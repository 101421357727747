import { BrowserRouter } from 'react-router-dom'

import ThemeContainer from './components/Theme/ThemeContainer'
import Routes from './containers'

import './styles/styles.scss'

const App = () => (
  <ThemeContainer>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ThemeContainer>
)

export default App
