import formatCurrency from '../../../../utils/currency'
import { decimate, formatHashrate } from '../../utils/metrics'

import './styles.scss'

const MetricsBoard = ({
  workerHashrate,
  validShares,
  invalidShares,
  workerRewards,
  coinPrice
}) => {
  const totShares = validShares + invalidShares

  return (
    <div className='metrics'>
      <div className='section'>
        <div>Worker hashrate</div>
        <div className='value'>{formatHashrate(workerHashrate, 1)}</div>
      </div>
      <div className='section'>
        <div>Valid shares</div>
        <div className='value'>
          {decimate(validShares, 0, 1).join('')}/
          {decimate(totShares, 0, 1).join('')} (
          {(totShares ? validShares / totShares : 0).toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          })}
          )
        </div>
      </div>
      <div className='section'>
        <div>Worker rewards</div>
        <div className='value'>{formatCurrency(workerRewards, 2, 4)} KDA</div>
      </div>
      <div className='section'>
        <div>Kadena price</div>
        <div className='value'>${formatCurrency(coinPrice, 2, 2)}</div>
      </div>
    </div>
  )
}

export default MetricsBoard
