import axios from 'axios'

const triggerFunction = async (route, data) => {
  try {
    const response = await axios.post(`https://api.nopool.com/${route}`, data, {
      headers: {
        'X-Api-Key': process.env.REACT_APP_LAMBDA_API_KEY
      }
    })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export default triggerFunction
