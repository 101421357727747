/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useMemo, useContext } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'

import ExternalLink from '../../../../components/SVGs/ExternalLink'

import AuthContext from '../../../../contexts/AuthContext'
import { formatTableTime } from '../../../../utils/dates'
import { formatHash } from '../../utils/metrics'

import './styles.scss'

const Table = memo(
  ({ data }) => {
    const authData = useContext(AuthContext)
    const { isAdmin } = authData

    let columns

    if (isAdmin) {
      columns = useMemo(
        () => [
          {
            Header: 'Time',
            id: 'time-table',
            accessor: row => formatTableTime(row.time)
          },
          {
            Header: 'Chain',
            id: 'chain-table',
            accessor: row => row.chain
          },
          {
            Header: 'Height',
            id: 'height-table',
            accessor: row => row.height
          },
          {
            Header: 'Hash',
            id: 'hash-table',
            accessor: row => {
              return (
                <div title={row.hash}>
                  {formatHash(row.hash)}
                  <a
                    className='externalLink'
                    href={`https://explorer.chainweb.com/mainnet/chain/${row.chain}/height/${row.height}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <ExternalLink width={12} height={12} />
                  </a>
                </div>
              )
            }
          },
          {
            Header: 'Reward',
            id: 'reward-table',
            accessor: row =>
              `${row.reward.toLocaleString(undefined, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              })} KDA`
          },
          {
            Header: 'Effort',
            id: 'effort-table',
            accessor: row =>
              (row.effort || 0).toLocaleString(undefined, {
                minimumFractionDigits: 4,
                maximumFractionDigits: 4
              })
          }
        ],
        []
      )
    } else {
      columns = useMemo(
        () => [
          {
            Header: 'Time',
            id: 'time-table',
            accessor: row => formatTableTime(row.time)
          },
          {
            Header: 'Chain',
            id: 'chain-table',
            accessor: row => row.chain
          },
          {
            Header: 'Height',
            id: 'height-table',
            accessor: row => row.height
          },
          {
            Header: 'Hash',
            id: 'hash-table',
            accessor: row => {
              return (
                <div title={row.hash}>
                  {formatHash(row.hash)}
                  <a
                    className='externalLink'
                    href={`https://explorer.chainweb.com/mainnet/chain/${row.chain}/height/${row.height}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <ExternalLink width={12} height={12} />
                  </a>
                </div>
              )
            }
          },
          {
            Header: 'Reward',
            id: 'reward-table',
            accessor: row =>
              `${row.reward.toLocaleString(undefined, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              })} KDA`
          }
        ],
        []
      )
    }

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: 10
        }
      },
      useSortBy,
      usePagination
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      state: { pageIndex }
    } = tableInstance

    return (
      <div className='metrics-table'>
        <p>Mined blocks</p>
        <div className='table-wrap'>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length !== 0 ? (
                page.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <button
            type='button'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            type='button'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            type='button'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          <button
            type='button'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          <br />
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length ? pageOptions.length : 1}
            </strong>
          </span>
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) {
      return false
    }

    return true
  }
)

export default Table
