import {
  isoToLocalDate,
  formatChartDate,
  formatChartTime
} from '../../../utils/dates'

const reduceAvgPoolHashrate = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return (
          sum +
          (element.elapsedTime ? element.hashCount / element.elapsedTime : 0)
        )
      }, 0) / incrementCount
    : 0
}

const reduceAvgNetworkHashrate = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return (
          sum +
          (element.elapsedTime
            ? element.networkHashCount / element.elapsedTime
            : 0)
        )
      }, 0) / incrementCount
    : 0
}

const reduceTotPoolRewards = data => {
  return data.reduce((sum, element) => {
    return sum + element.reward
  }, 0)
}

const reduceAvgCoinPrice = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return sum + element.price
      }, 0) / incrementCount
    : 0
}

const reduceAvgPoolMiners = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return sum + element.minerCount
      }, 0) / incrementCount
    : 0
}

const reduceAvgPoolWorkers = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return sum + element.workerCount
      }, 0) / incrementCount
    : 0
}

export const formatHash = hash => {
  return `${hash.slice(0, 7)}…`
}

export const formatHashCount = (hashCount, minDecimals, maxDecimals) => {
  let unit

  if (hashCount >= 1000000000000000000000) {
    hashCount /= 1000000000000000000000
    unit = 'ZH'
  } else if (hashCount >= 1000000000000000000) {
    hashCount /= 1000000000000000000
    unit = 'EH'
  } else if (hashCount >= 1000000000000000) {
    hashCount /= 1000000000000000
    unit = 'PH'
  } else if (hashCount >= 1000000000000) {
    hashCount /= 1000000000000
    unit = 'TH'
  } else if (hashCount >= 1000000000) {
    hashCount /= 1000000000
    unit = 'GH'
  } else if (hashCount >= 1000000) {
    hashCount /= 1000000
    unit = 'MH'
  } else if (hashCount >= 1000) {
    hashCount /= 1000
    unit = 'KH'
  } else {
    minDecimals = 0
    maxDecimals = 0
    unit = 'H'
  }

  return `${hashCount.toLocaleString(undefined, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits:
      typeof maxDecimals === 'number' ? maxDecimals : minDecimals
  })} ${unit}`
}

export const formatHashrate = (hashrate, minDecimals, maxDecimals) => {
  return `${formatHashCount(hashrate, minDecimals, maxDecimals)}/s`
}

export const processMetrics = convertedData => {
  const reducedAvgPoolHashrate = reduceAvgPoolHashrate(convertedData)
  const reducedAvgNetworkHashrate = reduceAvgNetworkHashrate(convertedData)
  const reducedTotPoolRewards = reduceTotPoolRewards(convertedData)
  const reducedAvgCoinPrice = reduceAvgCoinPrice(convertedData)
  const reducedAvgPoolMiners = reduceAvgPoolMiners(convertedData)
  const reducedAvgPoolWorkers = reduceAvgPoolWorkers(convertedData)

  return {
    reducedAvgPoolHashrate,
    reducedAvgNetworkHashrate,
    reducedTotPoolRewards,
    reducedAvgCoinPrice,
    reducedAvgPoolMiners,
    reducedAvgPoolWorkers
  }
}

export const convertJsonToArray = data => {
  let convertedData = []

  convertedData = Object.values(data)

  Object.keys(data).forEach((key, i) => {
    convertedData[i].date = isoToLocalDate(key)
    convertedData[i].isoDate = key
  })

  return convertedData
}

export const filterDataByDate = (data, startDate, endDate) => {
  const formattedStartDate = startDate.format('YYYY/MM/DD')
  const formattedEndDate = endDate.format('YYYY/MM/DD')

  const filteredConvertedData = data.filter(
    element =>
      element.date >= formattedStartDate && element.date <= formattedEndDate
  )

  return filteredConvertedData
}

export const populateChartData = (data, chartIndex) => {
  const chartData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  }

  data.forEach((value, index) => {
    const { time, date } = data[index]

    chartData.labels.push(
      time === '00:00:00' ? formatChartDate(date) : formatChartTime(time)
    )

    switch (chartIndex) {
      case 0: {
        chartData.datasets[0].data.push(
          data[index].elapsedTime
            ? data[index].hashCount / data[index].elapsedTime
            : 0
        )
        break
      }

      case 1: {
        chartData.datasets[0].data.push(
          data[index].elapsedTime
            ? data[index].networkHashCount / data[index].elapsedTime
            : 0
        )
        break
      }

      case 2: {
        chartData.datasets[0].data.push(data[index].reward)
        break
      }

      case 3: {
        chartData.datasets[0].data.push(data[index].price)
        break
      }

      case 4: {
        chartData.datasets[0].data.push(data[index].minerCount)
        break
      }

      default:
        chartData.datasets[0].data.push(data[index].workerCount)
    }
  })

  return chartData
}
