const formatCurrency = (value, minDigit, maxDigit) => {
  const fractionDigits = value > 1 ? minDigit : maxDigit

  const formattedValue = value
    .toLocaleString(undefined, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
    .replace('-0.00', '0.00')

  return `${formattedValue}`
}

export default formatCurrency
