import Copyright from '../../Copyright'
import Header from '../../Navbar/Header'

import './styles.scss'

const MinerLayout = ({ children }) => (
  <>
    <Header />
    <div className='miner-container'>
      <div className='miner'>{children}</div>
    </div>
    <Copyright />
  </>
)

export default MinerLayout
