import { useContext } from 'react'

import MinerLayout from '../../components/Layouts/MinerLayout'
import ApiKeyViewer from './components/ApiKeyViewer'
import EmailUpdater from './components/EmailUpdater'
import PasswordUpdater from './components/PasswordUpdater'

import AuthContext from '../../contexts/AuthContext'

import './styles.scss'

const Profile = () => {
  const authData = useContext(AuthContext)
  const { uid, email, walletAddress } = authData

  return (
    <MinerLayout>
      <div className='profile-container'>
        <ApiKeyViewer value={walletAddress || 'Contact help@joinmassive.com'} />
        <EmailUpdater uid={uid} email={email} />
        <PasswordUpdater email={email} />
      </div>
    </MinerLayout>
  )
}

export default Profile
