import { useContext } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'

import ThemeContext from '../../contexts/ThemeContext'

import './styles.scss'

const Captcha = ({ setToken }) => {
  const themeData = useContext(ThemeContext)
  const { theme } = themeData

  return (
    <div className='captcha-container'>
      <HCaptcha
        sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
        onVerify={setToken}
        onError={() => setToken(null)}
        onExpire={() => setToken(null)}
        theme={theme === 'dark' ? 'dark' : 'light'}
      />
    </div>
  )
}

export default Captcha
