import { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import Dashboard from './Dashboard'
import Worker from './Worker'
import Documentation from './Documentation'
import Login from './Login'
import Profile from './Profile'
import ResetPassword from './ResetPassword'
import SignUp from './SignUp'

import AuthContext from '../contexts/AuthContext'
import { isLoggedIn, getUserProfile } from '../services/user'
import useAnalytics from '../utils/analytics'
import RedirectRoute from '../utils/routes'

const Privacy = lazy(() => import('./Legal/Privacy'))
const Terms = lazy(() => import('./Legal/Terms'))
const EmailVerification = lazy(() => import('./Verification/EmailVerification'))
const ReviewApplication = lazy(() => import('./Verification/ReviewApplication'))

const useAuthRoutes = () => {
  const [routes, setRoutes] = useState()

  useEffect(() => {
    const setupRoutes = async () => {
      try {
        const uid = await isLoggedIn()

        if (!uid) {
          setRoutes(
            <AuthContext.Provider
              value={{
                uid,
                email: null,
                status: null,
                walletAddresses: {},
                isAdmin: false
              }}
            >
              <Suspense fallback={<></>}>
                <Switch>
                  <Route path='/login' component={Login} />
                  <Route path='/reset' component={ResetPassword} />
                  <Route path='/registration' component={SignUp} />
                  <Route path='/terms' component={Terms} />
                  <Route path='/privacy' component={Privacy} />
                  <Route path='/help' component={Documentation} />
                  <Route exact path='/' component={Dashboard} />
                  <Route path='/kda/:workerName' component={Worker} />
                  <Redirect to='/' />
                </Switch>
              </Suspense>
            </AuthContext.Provider>
          )
          return
        }

        const { email, status, walletAddresses } = await getUserProfile(uid)

        const isAdmin = email === 'admin@joinmassive.com'

        setRoutes(
          <AuthContext.Provider
            value={{
              uid,
              email,
              status,
              walletAddresses,
              isAdmin
            }}
          >
            <Suspense fallback={<></>}>
              <Switch>
                {status === 'created' && (
                  <Route path='/creation' component={EmailVerification} />
                )}
                {status === 'confirmed' && (
                  <Route path='/confirmation' component={ReviewApplication} />
                )}
                {(status === 'confirmed' ||
                  status === 'certified' ||
                  status === 'rejected') && (
                  <Route path='/profile' component={Profile} />
                )}
                <Route path='/terms' component={Terms} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/help' component={Documentation} />
                <Route exact path='/' component={Dashboard} />
                <Route path='/kda/:workerName' component={Worker} />
                <Route path='/redirect' component={RedirectRoute} />
                <Redirect to='/redirect' />
              </Switch>
            </Suspense>
          </AuthContext.Provider>
        )
      } catch (error) {
        NotificationManager.error(
          error.message,
          'Something went wrong – please try again.'
        )

        const Sentry = await import('@sentry/react')
        Sentry.captureException(error)
      }
    }

    setupRoutes()
  }, [])

  return routes
}

const Routes = () => {
  useAnalytics()

  const routes = useAuthRoutes()

  return (
    <>
      {routes}
      <NotificationContainer />
    </>
  )
}

export default Routes
