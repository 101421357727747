import './styles.scss'

const Infobox = () => {
  return (
    <div className='infobox'>
      <p>
        <strong>NoPool</strong> is a Stratum mining pool with Kadena support and
        the goals of <strong>(1)</strong> no centralization,{' '}
        <strong>(2)</strong> no emission footprint or conventional fees for
        low-emission miners, and <strong>(3)</strong> no performance compromise.
      </p>
      <p>
        Get started by specifying these options in your mining software of
        choice or see <a href='/help'>our documentation</a> for detailed
        instructions:
      </p>
      <pre>
        <code>
          <span className='option'>-o</span>{' '}
          <span className='value'>stratum+tcp://kda.beta.nopool.com:3700</span>{' '}
          <span className='option'>-u</span>{' '}
          <span className='value'>
            <em>wallet_address[.worker_name]</em>
          </span>
        </code>
      </pre>
      <p>
        <code>
          <span className='value'>
            <em>wallet_address</em>
          </span>
        </code>{' '}
        should be a valid Kadena address;{' '}
        <code>
          <span className='value'>
            <em>worker_name</em>
          </span>
        </code>{' '}
        lets you optionally name your worker using up to 15 alphanumeric
        characters separated from the address by a period.
      </p>
      <p>
        <a href='/login'>Creating an account</a> isn’t required to start mining
        but is before receiving payouts or emission credits.
      </p>
    </div>
  )
}

export default Infobox
