import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

const envStyles = {
  ...customStyles,
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'"
  })
}

const EnvDropdown = ({ width, options }) => (
  <Select
    className='react-select-container'
    classNamePrefix='react-select'
    defaultValue={options[0]}
    onChange={option => option.function()}
    options={options}
    isSearchable={false}
    hideSelectedOptions
    theme={customTheme}
    styles={envStyles}
    width={width}
  />
)

export default EnvDropdown
