const DesktopLogo = ({ width, height }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    viewBox='0 0 168 36'
    enableBackground='new 0 0 168 36'
    width={width}
    height={height}
  >
    <path d='M44.6,8.7v19.9H51v-9l7,9h5.5V8.7h-6.4v8.6l-6.7-8.6H44.6z' />
    <path
      id='_x3C_Path_x3E_'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.6,28.9c6.1,0,10.7-4.6,10.7-10.3v-0.1
        c0-5.7-4.6-10.2-10.7-10.2c-6.1,0-10.7,4.6-10.7,10.3v0.1C64.9,24.4,69.5,28.9,75.6,28.9z M75.6,23c-2.4,0-4.2-2-4.2-4.4v-0.1
        c0-2.4,1.7-4.3,4.1-4.3c2.4,0,4.1,2,4.1,4.4v0.1C79.7,21.1,78.1,23,75.6,23z'
    />
    <path
      id='_x3C_Path_x3E__1_'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M87.7,8.7v19.9h6.5v-5.3h1.9c2.4,0,4.6-0.6,6.1-1.8
        c1.6-1.3,2.5-3.1,2.5-5.5v-0.1c0-2.2-0.8-4-2.3-5.3c-1.5-1.2-3.6-1.9-6-1.9H87.7z M94.1,17.9v-3.6H96c0.8,0,1.4,0.2,1.8,0.5
        c0.3,0.3,0.6,0.7,0.6,1.3v0.1c0,0.5-0.2,1-0.6,1.3c-0.4,0.3-1,0.5-1.7,0.5H94.1z'
    />
    <path
      id='_x3C_Path_x3E__2_'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M115.7,28.9c6.1,0,10.7-4.6,10.7-10.3v-0.1
        c0-5.7-4.6-10.2-10.7-10.2c-6.1,0-10.7,4.6-10.7,10.3v0.1C105,24.4,109.6,28.9,115.7,28.9z M115.7,23c-2.4,0-4.2-2-4.2-4.4v-0.1
        c0-2.4,1.7-4.3,4.1-4.3c2.4,0,4.1,2,4.1,4.4v0.1C119.8,21.1,118.1,23,115.7,23z'
    />
    <path
      id='_x3C_Path_x3E__3_'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M137.8,28.9c6.1,0,10.7-4.6,10.7-10.3v-0.1
        c0-5.7-4.6-10.2-10.7-10.2c-6.1,0-10.7,4.6-10.7,10.3v0.1C127.1,24.4,131.7,28.9,137.8,28.9z M137.8,23c-2.4,0-4.2-2-4.2-4.4v-0.1
        c0-2.4,1.7-4.3,4.1-4.3c2.4,0,4.1,2,4.1,4.4v0.1C141.9,21.1,140.3,23,137.8,23z'
    />
    <path d='M156.3,8.7h-6.5v19.9h15.5v-5.8h-9V8.7z' />
    <linearGradient
      id='SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1='14.1056'
      y1='29.449'
      x2='16.0034'
      y2='33.1066'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#FE942D' />
      <stop offset='1' stopColor='#FFC633' />
    </linearGradient>
    <path
      fill='url(#SVGID_1_)'
      d='M15.9,4.7c1.8,0.3,3.4-0.6,3.2,0.9c-0.3,1.5-2,2.4-3.7,2.1c-1.8-0.3-3-1.8-2.7-3.3
        C12.9,2.9,14.1,4,15.9,4.7z'
    />
    <radialGradient
      id='SVGID_2_'
      cx='-872.1891'
      cy='592.826'
      r='1'
      gradientTransform='matrix(-16.5988 28.0124 25.8396 15.3114 -29778.0469 15369.8418)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#FFD748' />
      <stop offset='1' stopColor='#FFBF1B' />
    </radialGradient>
    <path
      fill='url(#SVGID_2_)'
      d='M13.3,6.4c-0.1-0.3-0.2-0.6-0.2-1c0-0.7,0-1.9,0.5-2.4c1.2,1.4,1.6-0.6,2.6-0.6c1.7,0,3,1.3,3,2.9
        c7.5,1.4,13.7,6.6,13.7,14.1c0,7.8-6.4,14.1-14.2,14.1c-7.8,0-14.2-6.3-14.2-14.1C4.5,13.6,8.2,8.5,13.3,6.4z'
    />
    <radialGradient
      id='SVGID_3_'
      cx='-910.2217'
      cy='579.8422'
      r='1'
      gradientTransform='matrix(-18.0815 4.8331 9.0197 33.7443 -21662.834 -15141.7949)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0.1624' stopColor='#FEC631' stopOpacity='0' />
      <stop offset='0.6511' stopColor='#FF930C' stopOpacity='0.4' />
      <stop offset='1' stopColor='#FF7A00' />
    </radialGradient>
    <path
      fill='url(#SVGID_3_)'
      fillOpacity='0.7'
      d='M13.3,6.4c-0.1-0.3-0.2-0.6-0.2-1c0-0.7,0-1.9,0.5-2.4c1.2,1.4,1.6-0.6,2.6-0.6
        c1.7,0,3,1.3,3,2.9c7.5,1.4,13.7,6.6,13.7,14.1c0,7.8-6.4,14.1-14.2,14.1c-7.8,0-14.2-6.3-14.2-14.1C4.5,13.6,8.2,8.5,13.3,6.4z'
    />
    <linearGradient
      id='_x3C_Path_x3E__7_'
      gradientUnits='userSpaceOnUse'
      x1='32.4101'
      y1='24.9379'
      x2='4.575'
      y2='15.6605'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#E77D0D' />
      <stop offset='1' stopColor='#9B5306' />
    </linearGradient>
    <path
      id='_x3C_Path_x3E__4_'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#_x3C_Path_x3E__7_)'
      fillOpacity='0.8'
      d='M33,16.2c0,2.2-2.2,3.2-4.2-1c-1.2,0-2,0.2-2.6,0.9c-0.2,0.3-0.4,0.8-0.5,1.4c-0.1,2.9-2.5,5.2-5.4,5.2c-2.4,0-4.5-1.6-5.2-3.8l-6-1
        c-1.4-0.2-2.9,0.4-3.7,1.6l-0.8,1.2c-0.3-1.6-0.4-2.7,0.6-4.5l1.3-0.8C7.2,15.1,8,14.9,8.8,15l4.4,0.4c0.8,0.1,1.7-0.1,2.4-0.6
        c0.5-1,1.4-1.8,2.4-2.3c2.3-1.7,6.8-3.4,10.6-3.3C31.3,9.1,32.6,12.1,33,16.2z M27,11.6l0.8,1.3c0.3,0.4,0.1,0.9-0.3,1.1l-0.9,0.4
        c-0.4,0.2-0.9,0-1-0.5l-0.5-1.4c-0.2-0.5,0.1-1.1,0.7-1.3C26.3,11,26.8,11.2,27,11.6z'
    />
    <linearGradient
      id='SVGID_4_'
      gradientUnits='userSpaceOnUse'
      x1='23.8388'
      y1='15.1924'
      x2='36.134'
      y2='10.3412'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#FF7000' />
      <stop offset='1' stopColor='#B95100' />
    </linearGradient>
    <path
      fill='url(#SVGID_4_)'
      d='M27.3,18.4c-2.6,2.8-4.1,5-6.1,9.4c-0.3,0.8-0.4,1.7,0,2.5c0.5,0.9,1.3,1.5,3,2.2
        c4.7,1,7.4,1.5,10.4-1.3c0.3-0.3,0.6-0.6,0.8-0.9c1-1.5,1-2.9-1-6.2c1.3-0.6,2.1-1.2,2.7-1.8c1.3-1.4,1-4.1-0.9-4.6
        c-1-0.3-2.4-0.2-4.5,0l-0.2-0.1C30.1,17,28.4,17.3,27.3,18.4z'
    />
    <radialGradient
      id='SVGID_5_'
      cx='-824.3356'
      cy='695.6932'
      r='1'
      gradientTransform='matrix(-1.4726 6.2889 6.9066 1.6173 -5989.77 4084.4971)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#A54800' />
      <stop offset='0.5227' stopColor='#DD6303' />
      <stop offset='0.9323' stopColor='#FF7000' />
    </radialGradient>
    <path
      fill='url(#SVGID_5_)'
      d='M26.5,31.7c-1.4-0.5-2.4-1.2-3.3-2.2c2.7-0.9,5.5-3,10.3-6.7c1.4,2,1.9,3.2,2.4,5.6
        C35.7,32.3,30.3,33,26.5,31.7z'
    />
    <linearGradient
      id='SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1='36.7906'
      y1='19.9743'
      x2='29.7705'
      y2='12.1933'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#FF7000' />
      <stop offset='0.6766' stopColor='#B95100' />
      <stop offset='1' stopColor='#7E3D0A' />
    </linearGradient>
    <path
      fill='url(#SVGID_6_)'
      d='M34.6,24.3c-4.6,0.3-7.1,2.3-11,5.1L31,19.2l1.4-1.3l2.7-0.2c1.8,0.2,2.4,0.6,2.9,1.9
        C38.1,21.8,37.6,22.7,34.6,24.3z'
    />
    <linearGradient
      id='_x3C_Path_x3E__8_'
      gradientUnits='userSpaceOnUse'
      x1='17.8595'
      y1='20.7557'
      x2='30.0417'
      y2='32.9928'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#000000' />
      <stop offset='1' stopColor='#686868' />
    </linearGradient>
    <path
      id='_x3C_Path_x3E__5_'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#_x3C_Path_x3E__8_)'
      d='M33.9,15.1
        c0,2.1-2.3,3.1-4.3-0.9c-1.3,0-2.1,0.2-2.6,0.8c-0.2,0.3-0.4,0.8-0.5,1.4c-0.1,2.8-2.6,5.1-5.6,5.1c-2.5,0-4.6-1.6-5.3-3.8l-6.4-1
        c-1.4-0.2-2.8,0.4-3.6,1.5l-1,1.3c-1-1.1-1.3-3.3,0.3-4.2l1.9-1.1c0.6-0.4,1.4-0.5,2.1-0.5l4.8,0.4c0.8,0.1,1.6-0.1,2.3-0.6
        c0.6-1,1.4-1.8,2.5-2.3c2.4-1.7,7-3.3,10.9-3.2C32.1,8.1,33.5,11,33.9,15.1z M27.7,10.5l0.8,1.2c0.3,0.4,0.1,0.9-0.3,1.1l-0.9,0.4
        c-0.4,0.2-0.9,0-1-0.5l-0.5-1.3c-0.2-0.6,0.1-1.2,0.7-1.3C26.9,9.9,27.4,10.1,27.7,10.5z'
    />
    <linearGradient
      id='_x3C_Path_x3E__9_'
      gradientUnits='userSpaceOnUse'
      x1='8.8073'
      y1='15.4653'
      x2='32.1668'
      y2='30.0825'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#000000' />
      <stop offset='1' stopColor='#797979' />
    </linearGradient>
    <path
      id='_x3C_Path_x3E__6_'
      fill='url(#_x3C_Path_x3E__9_)'
      d='M29.6,14.1L29.6,14.1L29.6,14.1L29.6,14.1L29.6,14.1z M33.9,15.1
        L33.9,15.1L33.9,15.1L33.9,15.1z M26.9,15L26.9,15L26.9,15L26.9,15z M26.4,16.3L26.4,16.3L26.4,16.3L26.4,16.3L26.4,16.3z
        M15.5,17.7L15.5,17.7L15.5,17.7L15.5,17.7L15.5,17.7z M9.1,16.7L9.1,16.7L9.1,16.7L9.1,16.7z M5.5,18.2L5.5,18.2L5.5,18.2L5.5,18.2
        z M4.5,19.5L4.5,19.5l0,0.1L4.5,19.5L4.5,19.5z M4.8,15.3L4.8,15.3L4.8,15.3L4.8,15.3z M6.7,14.3L6.7,14.3L6.7,14.3L6.7,14.3z
        M8.8,13.8L8.8,13.8L8.8,13.8L8.8,13.8z M13.6,14.2L13.6,14.2L13.6,14.2L13.6,14.2z M15.9,13.6L15.9,13.6L15.9,13.6L15.9,13.6
        L15.9,13.6z M18.4,11.3L18.4,11.3L18.4,11.3L18.4,11.3L18.4,11.3z M29.3,8L29.3,8L29.3,8L29.3,8z M28.5,11.7L28.5,11.7L28.5,11.7
        L28.5,11.7z M27.7,10.5L27.7,10.5L27.7,10.5L27.7,10.5z M28.2,12.8L28.2,12.8L28.2,12.8L28.2,12.8z M27.2,13.2L27.2,13.2L27.2,13.2
        L27.2,13.2z M26.3,12.7L26.3,12.7L26.3,12.7L26.3,12.7z M25.8,11.4L25.8,11.4L25.8,11.4L25.8,11.4z M26.5,10L26.5,10L26.5,10
        L26.5,10z M29.5,14.2c0.5,1,1,1.7,1.5,2.2c0.5,0.5,1,0.7,1.4,0.7c0.9,0,1.5-0.9,1.5-2l-0.2,0c0,1-0.6,1.8-1.3,1.8
        c-0.4,0-0.8-0.2-1.3-0.6c-0.5-0.4-1-1.1-1.5-2.1L29.5,14.2z M27,15c0.5-0.6,1.3-0.8,2.6-0.8l0-0.2c-1.3,0-2.1,0.2-2.7,0.9L27,15z
        M26.5,16.4c0.1-0.5,0.3-1.1,0.5-1.4l-0.2-0.1c-0.2,0.3-0.4,0.9-0.5,1.4L26.5,16.4z M20.8,21.6c3,0,5.5-2.3,5.7-5.2l-0.2,0
        c-0.1,2.8-2.5,5-5.5,5V21.6z M15.4,17.7c0.7,2.2,2.9,3.8,5.4,3.8v-0.2c-2.5,0-4.6-1.6-5.2-3.7L15.4,17.7z M9.1,16.8l6.4,1l0-0.2
        l-6.4-1L9.1,16.8z M5.6,18.2c0.8-1.1,2.2-1.7,3.5-1.5l0-0.2c-1.4-0.2-2.8,0.4-3.7,1.5L5.6,18.2z M4.6,19.6l1-1.3l-0.2-0.1l-1,1.3
        L4.6,19.6z M4.7,15.2c-0.8,0.5-1.2,1.3-1.2,2.1c0,0.8,0.3,1.7,0.9,2.3l0.1-0.1c-0.5-0.5-0.8-1.3-0.8-2.1c0-0.8,0.3-1.5,1.1-1.9
        L4.7,15.2z M6.6,14.2l-1.9,1.1l0.1,0.2l1.9-1.1L6.6,14.2z M8.8,13.7c-0.8-0.1-1.5,0.1-2.2,0.5l0.1,0.2c0.6-0.3,1.3-0.5,2.1-0.4
        L8.8,13.7z M13.6,14.1l-4.8-0.4l0,0.2l4.8,0.4L13.6,14.1z M15.8,13.5c-0.7,0.4-1.4,0.6-2.2,0.6l0,0.2c0.8,0.1,1.7-0.1,2.4-0.6
        L15.8,13.5z M18.4,11.2c-1.1,0.5-2,1.3-2.6,2.4l0.2,0.1c0.5-1,1.4-1.8,2.5-2.3L18.4,11.2z M29.4,7.9c-3.9-0.1-8.5,1.6-11,3.3
        l0.1,0.2c2.4-1.7,7-3.3,10.9-3.2L29.4,7.9z M34,15.1c-0.2-2-0.7-3.8-1.4-5.1c-0.7-1.3-1.8-2-3.2-2l0,0.2c1.3,0,2.3,0.7,3.1,1.9
        c0.7,1.2,1.2,3,1.4,5L34,15.1z M28.6,11.6l-0.8-1.2l-0.2,0.1l0.8,1.2L28.6,11.6z M28.2,12.9c0.5-0.2,0.7-0.8,0.4-1.2l-0.2,0.1
        c0.2,0.3,0.1,0.8-0.3,1L28.2,12.9z M27.3,13.3l0.9-0.4l-0.1-0.2l-0.9,0.4L27.3,13.3z M26.2,12.8c0.2,0.5,0.7,0.7,1.1,0.5l-0.1-0.2
        c-0.3,0.1-0.7,0-0.9-0.4L26.2,12.8z M25.7,11.4l0.5,1.3l0.2-0.1l-0.5-1.3L25.7,11.4z M26.5,10c-0.6,0.2-1,0.8-0.8,1.5l0.2-0.1
        c-0.2-0.5,0.1-1.1,0.6-1.2L26.5,10z M27.8,10.4C27.5,10,27,9.8,26.5,10l0.1,0.2c0.4-0.1,0.8,0,1.1,0.4L27.8,10.4z'
    />
    <linearGradient
      id='SVGID_7_'
      gradientUnits='userSpaceOnUse'
      x1='29.0803'
      y1='22.0675'
      x2='32.7199'
      y2='29.333'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#444444' />
      <stop offset='1' stopColor='#000000' />
    </linearGradient>
    <path
      fill='url(#SVGID_7_)'
      d='M32.8,11.9c0.8,2,0.7,4.1-0.2,4.4c-0.9,0.3-2.3-1.2-3.1-3.2c-2-5.2-1-4.3,0.3-4.3
        C31.1,9,32,9.9,32.8,11.9z'
    />
    <linearGradient
      id='SVGID_8_'
      gradientUnits='userSpaceOnUse'
      x1='18.4711'
      y1='16.5612'
      x2='24.2804'
      y2='28.5677'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#444444' />
      <stop offset='1' stopColor='#000000' />
    </linearGradient>
    <path
      fill='url(#SVGID_8_)'
      d='M25.9,15.8c0,2.6-2.2,4.7-4.9,4.7c-2.7,0-4.9-2.1-4.9-4.7c0-2.6,2.3-3.6,4.9-5.2
        C24.4,8.6,25.9,13.2,25.9,15.8z'
    />
    <radialGradient
      id='SVGID_9_'
      cx='-859.5753'
      cy='599.2694'
      r='1'
      gradientTransform='matrix(-5.551 17.4515 23.412 7.447 -18773.4316 10549.7441)'
      gradientUnits='userSpaceOnUse'
    >
      <stop offset='0' stopColor='#FF9921' />
      <stop offset='0.4772' stopColor='#FF9C07' />
      <stop offset='1' stopColor='#FF7000' />
    </radialGradient>
    <path
      fill='url(#SVGID_9_)'
      d='M20.8,28.8c1.3,1.3,2.9,1.4,5.9-1.1l3.2-2.3l1.8-1.8c0.5-0.5,0.9-1,1.2-1.5l1.1-1.8
        c0.8-1.4,2.6-1.7,4-0.8c-0.1-0.4-0.3-0.8-0.7-1.1c-1.1-0.9-2.7-1-5.6-0.6l-1.3-0.6c-0.8-0.4-1.8-0.2-2.4,0.4
        c-3.2,3.4-4.8,5.6-7.1,10.8C20.9,28.4,20.9,28.6,20.8,28.8z'
    />
    <linearGradient
      id='SVGID_10_'
      gradientUnits='userSpaceOnUse'
      x1='39.8575'
      y1='22.1123'
      x2='26.9971'
      y2='10.6924'
      gradientTransform='matrix(1 0 0 -1 0 37.8306)'
    >
      <stop offset='0' stopColor='#FFFFFF' />
      <stop offset='1' stopColor='#F8AA62' stopOpacity='0' />
    </linearGradient>
    <path
      fill='none'
      stroke='url(#SVGID_10_)'
      strokeWidth='1.1062'
      d='M38.2,18.9c-0.8-0.4-1.7-0.9-3.2,0c-1.1,0.7-1.7,2.3-2,2.8
        l-2.4,2.5c-0.5,0.5-1.1,1-1.6,1.4l-2.2,1.5'
    />
  </svg>
)

export default DesktopLogo
