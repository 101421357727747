import { useState, useEffect, useContext } from 'react'
import { NotificationManager } from 'react-notifications'

import EmailDropdown from '../../../../components/Dropdowns/EmailDropdown'

import { fetchMinersData, sortMinerEmails } from '../../utils/admin'
import AuthContext from '../../../../contexts/AuthContext'

const AdminTools = ({ activeAddress }) => {
  const [walletAddress, setWalletAddress] = useState(
    (useContext(AuthContext).walletAddresses || {}).kda
  )
  const [minersData, setMinersData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMinersData()

        setMinersData(data)
      } catch (error) {
        NotificationManager.error(
          error.message,
          'Something went wrong – please try again.'
        )

        const Sentry = await import('@sentry/react')
        Sentry.captureException(error)
      }
    }

    if (activeAddress) {
      setWalletAddress(activeAddress)
    }

    fetchData()
  }, [activeAddress])

  return (
    minersData.length !== 0 && (
      <EmailDropdown
        options={sortMinerEmails(minersData)}
        defaultOption={minersData.find(miner => miner.value === walletAddress)}
        width='280px'
      />
    )
  )
}

export default AdminTools
