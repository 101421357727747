import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import './styles.scss'

const SearchBox = ({ placeholder, value }) => {
  const [term, setTerm] = useState('')
  const history = useHistory()

  const handleSearch = async event => {
    event.preventDefault()
    const resolvedTerm = term || value

    if (resolvedTerm) {
      history.push(`/kda/${resolvedTerm}`)
    }
  }

  return (
    <div className='search'>
      <form onSubmit={handleSearch}>
        <label htmlFor='term'>{placeholder}</label>
        <input
          id='term'
          placeholder={placeholder}
          defaultValue={value}
          onChange={event => setTerm(event.target.value)}
        />
        <button type='submit'>Look up</button>
      </form>
    </div>
  )
}

export default SearchBox
