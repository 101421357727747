import { useState, useEffect, useRef } from 'react'

import ThemeContext from '../../../contexts/ThemeContext'

const Theme = ({ children }) => {
  const [theme, setTheme] = useState('light')

  const didMountRef = useRef(false)

  useEffect(() => {
    const storageValue = localStorage.getItem('theme')

    if (storageValue && JSON.parse(storageValue) === 'dark') {
      setTheme('dark')
    } else if (storageValue && JSON.parse(storageValue) === 'light') {
      setTheme('light')
    } else {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

      setTheme(mediaQuery.matches ? 'dark' : 'light')
    }
  }, [])

  useEffect(() => {
    if (didMountRef.current) {
      localStorage.setItem('theme', JSON.stringify(theme))

      document
        .getElementsByTagName('html')[0]
        .classList.toggle('dark', theme === 'dark')
    } else {
      didMountRef.current = true
    }
  }, [theme])

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')

    const chart = window.Chart && window.Chart.instances[0]

    if (chart) {
      const xAxes = chart.options.scales.xAxes[0]
      const yAxes = chart.options.scales.yAxes[0]

      if (xAxes && yAxes) {
        const color = theme === 'light' ? '#999999' : '#00000080'

        xAxes.ticks.fontColor = color
        yAxes.ticks.fontColor = color
        chart.update()
      }
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default Theme
