import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

import './styles.scss'

export const reportStyles = {
  ...customStyles,
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'",
    display: 'inline-block'
  })
}

const MonthlyDropdown = ({ width, options }) => (
  <Select
    className='react-select-container months'
    classNamePrefix='react-select'
    defaultValue={options[2]}
    onChange={option => option.function()}
    options={options}
    isSearchable={false}
    hideSelectedOptions
    theme={customTheme}
    styles={reportStyles}
    width={width}
    placeholder='Monthly reports'
  />
)

export default MonthlyDropdown
