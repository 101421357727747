import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

import Captcha from '../../../../components/Captcha'
import { confirmPasswordReset } from '../../../../services/user'

const EmailForm = () => {
  const [email, setEmail] = useState('')
  const [alreadySent, setAlreadySent] = useState(false)
  const [token, setToken] = useState(null)

  const handlePasswordReset = async event => {
    event.preventDefault()

    if (alreadySent) {
      return
    }

    if (!token) {
      NotificationManager.error('You must verify that you’re human.')
      return
    }

    document.body.style.cursor = 'wait'

    try {
      await confirmPasswordReset(email, token)
      setAlreadySent(true)
      NotificationManager.success(
        'Check your email to continue the password reset.'
      )
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <form onSubmit={handlePasswordReset}>
      <input
        required
        type='text'
        placeholder='Your email'
        id='email-resetpassword'
        name='email'
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      <Captcha setToken={setToken} />
      <button type='submit' className='btn-submit'>
        Reset password
      </button>
    </form>
  )
}

export default EmailForm
