import { useState, useContext } from 'react'

import SideDrawer from '../SideDrawer'
import Toolbar from '../Toolbar'

import AuthContext from '../../../contexts/AuthContext'

import './styles.scss'

const Header = () => {
  const authData = useContext(AuthContext)
  const { email } = authData

  const [showSideDrawer, setShowSideDrawer] = useState(false)

  return (
    <>
      <Toolbar
        drawerToggleClicked={() => setShowSideDrawer(!showSideDrawer)}
        email={email}
      />
      <SideDrawer
        open={showSideDrawer}
        closed={() => setShowSideDrawer(false)}
        email={email}
      />
      <main className='content' />
    </>
  )
}

export default Header
