import MobileLogo from '../../SVGs/MobileLogo'
import DesktopLogo from '../../SVGs/DesktopLogo'

import './styles.scss'

const Logo = ({ mobile }) => (
  <div className='logo'>
    <a href='/'>
      {mobile ? (
        <MobileLogo width={180} height={56} />
      ) : (
        <DesktopLogo width={160} height={56} />
      )}
    </a>
  </div>
)

export default Logo
