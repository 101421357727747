import Logo from '../Logo'
import NavigationItems from '../NavigationItems'
import Backdrop from '../Backdrop'

import './styles.scss'

const SideDrawer = ({ open, closed, email }) => (
  <>
    <Backdrop show={open} clicked={closed} />
    <div className={`side-drawer ${open ? 'open' : 'close'}`}>
      <div className='logo-side-drawer'>
        <Logo mobile />
      </div>
      <nav>
        <NavigationItems email={email} clicked={closed} drawer />
      </nav>
    </div>
  </>
)

export default SideDrawer
