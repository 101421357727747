import { useContext } from 'react'

import AuthContext from '../../../../contexts/AuthContext'
import formatCurrency from '../../../../utils/currency'
import { formatHashrate } from '../../utils/metrics'

import './styles.scss'

const MetricsBoard = ({
  poolHashrate,
  networkHashrate,
  poolRewards,
  coinPrice,
  poolMiners,
  poolWorkers
}) => {
  const authData = useContext(AuthContext)
  const { isAdmin } = authData

  return (
    <div className='metrics'>
      <div className='section'>
        <div>NoPool hashrate</div>
        <div className='value'>{formatHashrate(poolHashrate, 1)}</div>
      </div>
      <div className='section'>
        <div>Kadena hashrate</div>
        <div className='value'>{formatHashrate(networkHashrate, 1)}</div>
      </div>
      <div className='section'>
        <div>NoPool rewards</div>
        <div className='value'>{formatCurrency(poolRewards, 2, 4)} KDA</div>
      </div>
      <div className='section'>
        <div>Kadena price</div>
        <div className='value'>${formatCurrency(coinPrice, 2, 2)}</div>
      </div>
      {isAdmin && (
        <>
          <div className='section'>
            <div>NoPool miners</div>
            <div className='value'>
              {poolMiners.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
              })}{' '}
              M/min
            </div>
          </div>
          <div className='section'>
            <div>NoPool workers</div>
            <div className='value'>
              {poolWorkers.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
              })}{' '}
              W/min
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MetricsBoard
