import { Children, createElement } from 'react'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'

import prism from './prism-styles'

const flatten = (text, child) =>
  typeof child === 'string'
    ? text + child
    : Children.toArray(child.props.children).reduce(flatten, text)

const headingRenderer = ({ level, children }) => {
  const childrenArray = Children.toArray(children)
  const text = childrenArray.reduce(flatten, '')
  const slug = text.toLowerCase().replace(/\W/g, '-')
  return createElement(`h${level}`, { id: slug }, children)
}

const codeRenderer = ({ language, value }) => (
  <SyntaxHighlighter language={language} style={prism}>
    {value}
  </SyntaxHighlighter>
)

const renderers = {
  heading: headingRenderer,
  code: codeRenderer
}

export default renderers
