import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

import Button from '../../elements/Button'
import Input from '../../elements/Input'
import SectionTitle from '../../elements/SectionTitle'

import './styles.scss'

const ApiKey = ({ value }) => {
  const [showValue, setShowValue] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value)
    NotificationManager.info('The address was copied to your clipboard.')
  }

  const toggleVisibility = () => {
    setShowValue(!showValue)
  }

  return (
    <>
      <SectionTitle title='Wallet address' />
      <div className='wallet-address'>
        <Input
          type={showValue ? 'text' : 'password'}
          name='wallet-address'
          value={value}
          disabled
          autoComplete='wallet-address'
        />
        <Button
          type='button'
          label={showValue ? 'Hide' : 'Show'}
          onClick={toggleVisibility}
        />
        <Button type='button' label='Copy' onClick={copyToClipboard} />
      </div>
    </>
  )
}

export default ApiKey
