const filterByDate = (data, date) =>
  data.filter(element => {
    const elementDate = new Date(element.date)

    return elementDate <= date
  })

const reducePayouts = filteredPayouts =>
  filteredPayouts.reduce((sum, element) => sum + element.payout, 0)

const processPayoutsByDate = (payoutsData, payoutDate) => {
  const filteredPayouts = filterByDate(payoutsData, payoutDate)

  const reducedPayouts = reducePayouts(filteredPayouts)

  return reducedPayouts
}

const reduceIncome = incomeData =>
  incomeData.reduce((sum, element) => sum + element.reward, 0)

const processIncomeByDate = (incomeData, payoutDate) => {
  const filteredIncome = filterByDate(incomeData, payoutDate)

  const reducedIncome = reduceIncome(filteredIncome)

  return reducedIncome
}

const processCurrentBalance = (incomeData, payoutsData) => {
  const currentBalance = []

  payoutsData.forEach(payout => {
    const payoutDate = new Date(payout.date)
    const processedPayouts = processPayoutsByDate(payoutsData, payoutDate)
    const processedIncome = processIncomeByDate(incomeData, payoutDate)

    currentBalance.push(processedIncome - processedPayouts)
  })

  return currentBalance
}

const addBalanceToPayouts = (incomeData, payoutsData) => {
  const currentBalance = processCurrentBalance(incomeData, payoutsData)

  const payoutsWithBalance = payoutsData.map((payout, index) => ({
    ...payout,
    currentBalance: currentBalance[index] || 0
  }))

  return payoutsWithBalance
}

const processWallet = (incomeData, payouts) => {
  const { payoutsData, totalPayoutDollars } = payouts

  const income = reduceIncome(incomeData)

  const payoutsWithBalance = addBalanceToPayouts(incomeData, payoutsData)

  return { income, totalPayoutDollars, payoutsWithBalance }
}

export default processWallet
