import { useContext, useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'

import Header from '../../components/Navbar/Header'
import WorkerDropdown from '../../components/Dropdowns/WorkerDropdown'
import MonthlyDropdown from '../../components/Dropdowns/MonthlyDropdown'
import Spinner from '../../components/Spinner'
import ChartDropdown from '../../components/Dropdowns/ChartDropdown'
import Copyright from '../../components/Copyright'

import SearchBox from '../Dashboard/components/SearchBox'
import AdminTools from '../Dashboard/components/AdminTools'
import DatePicker from '../Dashboard/components/DatePicker'
import Timestamp from '../Dashboard/components/Timestamp'
import BalanceBoard from '../Dashboard/components/BalanceBoard'
import NoData from '../Dashboard/components/NoData'
import MetricsTable from '../Dashboard/components/MetricsTable'
import PayoutsTable from '../Dashboard/components/PayoutsTable'

import Chart from './components/Chart'
import MetricsBoard from './components/MetricsBoard'

import AuthContext from '../../contexts/AuthContext'
import {
  today,
  yesterday,
  startOfMonth,
  endOfMonth,
  formatReportDate
} from '../../utils/dates'
import {
  fetchPathData,
  fetchNetworkData,
  fetchPpsData,
  fetchBlocks,
  fetchPayoutsData
} from '../../services/dashboard'
import processWallet from '../Dashboard/utils/wallet'
import {
  convertJsonToArray,
  filterDataByDate,
  processMetrics,
  populateChartData
} from './utils/metrics'

import './styles.scss'

const Worker = ({ match }) => {
  const { workerName } = match.params
  const { isAdmin } = useContext(AuthContext)
  const [walletAddress, setWalletAddress] = useState('')
  const [selectedWorkerName, setSelectedWorkerName] = useState('')
  const [workerNames, setWorkerNames] = useState([])
  const [showSpinner, setShowSpinner] = useState(true)
  const [startDate, setStartDate] = useState(yesterday())
  const [endDate, setEndDate] = useState(today())
  const [payoutsData, setPayoutsData] = useState([])
  const [rawDashboardData, setRawDashboardData] = useState({})
  const [rawPoolBlocks, setRawPoolBlocks] = useState({})
  const [convertedDashboardData, setConvertedDashboardData] = useState([])
  const [filteredPoolBlocks, setFilteredPoolBlocks] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [chartData, setChartData] = useState({})
  const [chartIndex, setChartIndex] = useState(0)
  const [poolBlocks, setPoolBlocks] = useState([])
  const [poolFee, setPoolFee] = useState(0)
  const [timestamp, setTimestamp] = useState('')
  const [monthlyOptions, setMonthlyOptions] = useState([])
  const [metrics, setMetrics] = useState({
    workerHashrate: 0,
    validShares: 0,
    invalidShares: 0,
    workerRewards: 0,
    coinPrice: 0
  })

  useEffect(() => {
    const fetchAndSetDashboardData = async () => {
      try {
        const components = workerName.split('.')
        const address = components[0]
        const name = components[1]

        setWalletAddress(address)

        const walletData = await fetchPathData(
          'wallets/kda',
          `${address}/workers`
        )

        if (!walletData) {
          setShowSpinner(false)
          return
        }

        const names = Object.keys(walletData)
        const formattedNames = names.sort().map(value => {
          return { value, label: value }
        })

        setSelectedWorkerName(name || names[0])
        setWorkerNames(formattedNames)

        const data = name
          ? walletData[name] || Object.values(walletData)[0]
          : Object.values(walletData)[0]
        const [networkData, ppsData, blocks] = await Promise.all([
          fetchNetworkData(),
          fetchPpsData(),
          fetchBlocks()
        ])

        if (!data || !networkData || !ppsData || !blocks) {
          setShowSpinner(false)
          return
        }

        const days = Object.keys(networkData)
        const lastDay = days.length - 1
        const rawData = JSON.parse(JSON.stringify(networkData))
        const rawBlocks = JSON.parse(JSON.stringify(networkData))

        days.forEach((date, i) => {
          const dailyNetworkData = networkData[date]
          const dailyData = data[date] || {}
          const times = Object.keys(dailyNetworkData)
          const lastTime = times.length - 1

          times.forEach((time, j) => {
            if (i === lastDay && j === lastTime) {
              delete dailyNetworkData[time]
              return
            }

            const incrementalNetworkData = dailyNetworkData[time]
            const incrementalData = dailyData[time] || {}
            const shares = incrementalData.shares || {}

            incrementalNetworkData.utcTimestamp =
              incrementalNetworkData.utcTimestamp || '00:00:00'
            incrementalNetworkData.price = incrementalNetworkData.usdPrice || 0

            incrementalNetworkData.elapsedTime =
              incrementalData.elapsedSeconds &&
              incrementalData.elapsedSeconds >= 55
                ? incrementalData.elapsedSeconds
                : 60
            incrementalNetworkData.hashCount = incrementalData.hashCount || 0
            incrementalNetworkData.difficulty =
              incrementalNetworkData.targetDifficulty || 0
            incrementalNetworkData.reward = incrementalData.kdaReward || 0
            incrementalNetworkData.validCount = shares.validCount || 0
            incrementalNetworkData.invalidCount = shares.invalidCount || 0

            incrementalNetworkData.fee = ppsData.fee
          })

          rawData[date].data = dailyNetworkData
          rawBlocks[date].blocks = blocks[date] || {}
        })

        setRawDashboardData(rawData)
        setRawPoolBlocks(rawBlocks)
      } catch (error) {
        NotificationManager.error(
          error.message,
          'Data failed to load – please try again.'
        )

        const Sentry = await import('@sentry/react')
        Sentry.captureException(error)
      }
    }

    fetchAndSetDashboardData()
    const interval = setInterval(fetchAndSetDashboardData, 120000)

    return () => {
      clearInterval(interval)
    }
  }, [workerName])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const convertedData = convertJsonToArray(rawDashboardData)
      const convertedBlocks = convertJsonToArray(rawPoolBlocks)

      const filteredData = filterDataByDate(convertedData, startDate, endDate)
      const filteredBlocks = filterDataByDate(
        convertedBlocks,
        startDate,
        endDate
      )

      const explodedData = filteredData.reduce((data, rawData) => {
        const dailyData = rawData.data

        Object.keys(dailyData).forEach(time => {
          const incrementalData = dailyData[time]
          incrementalData.date = rawData.date
          incrementalData.time = time

          data.push(incrementalData)
        })

        return data
      }, [])

      setConvertedDashboardData(convertedData)
      setFilteredPoolBlocks(filteredBlocks)
      setDashboardData(explodedData)
      setShowSpinner(false)
    }
  }, [rawDashboardData, rawPoolBlocks, startDate, endDate])

  useEffect(() => {
    if (dashboardData.length !== 0) {
      const processedMetrics = processMetrics(dashboardData)

      setMetrics({
        workerHashrate: processedMetrics.reducedAvgWorkerHashrate,
        validShares: processedMetrics.reducedTotValidShares,
        invalidShares: processedMetrics.reducedTotInvalidShares,
        workerRewards: processedMetrics.reducedTotWorkerRewards,
        coinPrice: processedMetrics.reducedAvgCoinPrice
      })
    }
  }, [dashboardData])

  useEffect(() => {
    if (dashboardData.length !== 0) {
      const filledChartData = populateChartData(dashboardData, chartIndex)

      setChartData(filledChartData)
    }
  }, [dashboardData, chartIndex])

  useEffect(() => {
    if (filteredPoolBlocks.length !== 0) {
      const explodedBlocks = filteredPoolBlocks
        .reverse()
        .reduce((blocks, rawBlocks) => {
          const dailyBlocks = rawBlocks.blocks

          Object.keys(dailyBlocks)
            .reverse()
            .forEach(time => {
              const incrementalBlock = dailyBlocks[time]

              incrementalBlock.time = `${rawBlocks.isoDate}T${time}`
              blocks.push(incrementalBlock)
            })

          return blocks
        }, [])

      setPoolBlocks(explodedBlocks)
    }
  }, [filteredPoolBlocks])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const dailyData = Object.values(rawDashboardData)
      const lastDay = dailyData[dailyData.length - 1]
      const incrementalData = Object.values(lastDay.data)
      const lastIncrement = incrementalData[incrementalData.length - 1]
      const { fee, utcTimestamp } = lastIncrement

      setPoolFee(fee)
      setTimestamp(utcTimestamp)
    }
  }, [rawDashboardData])

  useEffect(() => {
    if (convertedDashboardData.length !== 0) {
      const fetchWalletData = async () => {
        try {
          const payouts = await fetchPayoutsData(walletAddress)
          const processedWallet = processWallet(convertedDashboardData, payouts)

          setPayoutsData(processedWallet.payoutsWithBalance)
        } catch (error) {
          NotificationManager.error(
            error.message,
            'Payouts failed to load – please try again.'
          )

          const Sentry = await import('@sentry/react')
          Sentry.captureException(error)
        }
      }

      fetchWalletData()
    }
  }, [walletAddress, convertedDashboardData])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const yearMonth = []

      Object.keys(rawDashboardData).forEach(date => {
        const slicedDate = date.slice(0, 7)

        const dateIndex = yearMonth.indexOf(slicedDate)

        if (dateIndex === -1) {
          yearMonth.push(slicedDate)
        }
      })

      const options = []

      yearMonth.forEach(month => {
        const formattedDate = formatReportDate(month)

        options.push({
          value: formattedDate,
          label: formattedDate,
          function: () => {
            const firstDay = startOfMonth(month)
            const lastDay = endOfMonth(month)

            setStartDate(firstDay)
            setEndDate(lastDay)
          }
        })
      })

      setMonthlyOptions(options)
    }
  }, [rawDashboardData])

  let content
  let hasNoData

  if (showSpinner) {
    content = <Spinner />
    hasNoData = true
  } else if (dashboardData.length > 0) {
    const chartOptions = isAdmin
      ? [
          {
            value: 'worker-hashrate',
            label: 'Worker hashrate',
            function: () => setChartIndex(0)
          },
          {
            value: 'valid-shares',
            label: 'Valid shares',
            function: () => setChartIndex(1)
          },
          {
            value: 'worker-rewards',
            label: 'Worker rewards',
            function: () => setChartIndex(2)
          },
          {
            value: 'coin-price',
            label: 'Kadena price',
            function: () => setChartIndex(3)
          }
        ]
      : [
          {
            value: 'worker-hashrate',
            label: 'Worker hashrate',
            function: () => setChartIndex(0)
          },
          {
            value: 'valid-shares',
            label: 'Valid shares',
            function: () => setChartIndex(1)
          },
          {
            value: 'worker-rewards',
            label: 'Worker rewards',
            function: () => setChartIndex(2)
          },
          {
            value: 'coin-price',
            label: 'Kadena price',
            function: () => setChartIndex(3)
          }
        ]

    content = (
      <>
        <ChartDropdown width='180px' options={chartOptions} />
        <Chart data={chartData} index={chartIndex} />
        {isAdmin && <MetricsTable data={poolBlocks} />}
        <PayoutsTable data={[...payoutsData].reverse()} />
      </>
    )
  } else {
    content = <NoData />
    hasNoData = true
  }

  return (
    <>
      <Header />
      <div className='worker-container'>
        <SearchBox
          placeholder='Wallet address or worker name'
          value={walletAddress}
        />
        <div className='worker-tools'>
          {isAdmin && <AdminTools activeAddress={walletAddress} />}
          {workerNames.length !== 0 && (
            <WorkerDropdown
              options={workerNames}
              defaultOption={{
                value: selectedWorkerName,
                label: selectedWorkerName
              }}
              walletAddress={walletAddress}
              width='180px'
            />
          )}
        </div>
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          firstDate={convertedDashboardData[0]?.date}
        />
        {isAdmin && (
          <MonthlyDropdown
            width='180px'
            options={[...monthlyOptions].reverse()}
          />
        )}
        <Timestamp timestamp={timestamp} />
        <BalanceBoard poolFee={poolFee} />
        <MetricsBoard
          workerHashrate={metrics.workerHashrate}
          validShares={metrics.validShares}
          invalidShares={metrics.invalidShares}
          workerRewards={metrics.workerRewards}
          coinPrice={metrics.coinPrice}
        />
        {content}
      </div>
      <Copyright hasNoData={hasNoData} />
    </>
  )
}

export default Worker
