import {
  fetchWalletAddresses,
  fetchPathData
} from '../../../services/dashboard'

export const fetchMinersData = async () => {
  try {
    const wallets = await fetchWalletAddresses()

    const minersData = await Promise.all(
      wallets.map(async wallet => {
        const { email } = (await fetchPathData('miners', wallet.minerId)) || ''
        const walletAddress = wallet.address

        return {
          value: walletAddress,
          label: email || walletAddress
        }
      })
    )

    return minersData
  } catch (error) {
    throw new Error(error)
  }
}

export const sortMinerEmails = minerEmails =>
  minerEmails.slice().sort((a, b) => {
    const emailA = a.label.toLowerCase()
    const emailB = b.label.toLowerCase()

    if (emailA < emailB) {
      return -1
    }
    if (emailA > emailB) {
      return 1
    }

    return 0
  })
