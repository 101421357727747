/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import toc from 'remark-toc'

import MinerLayout from '../../components/Layouts/MinerLayout'
import EnvDropdown from '../../components/Dropdowns/EnvDropdown'

import renderers from './utils/renderers'

import introduction from './markdown/introduction.md'
import bitmain from './markdown/bitmain-antminerka3.md'
import goldshell from './markdown/goldshell-kdmax.md'
import ibelink from './markdown/ibelink-bmk1max.md'
import questions from './markdown/questions.md'

import './styles.scss'

const Documentation = () => {
  const [intro, setIntro] = useState('')
  const [doc, setDoc] = useState('')

  const fetchAndSetIntroduction = async file => {
    const response = await fetch(file)
    const text = await response.text()

    setIntro(text)
  }

  const fetchAndSetMarkdown = async files => {
    let text = ''

    for (const file of files) {
      const response = await fetch(file)
      text += await response.text()
    }

    setDoc(text)
  }

  useEffect(() => {
    fetchAndSetIntroduction(introduction)
    fetchAndSetMarkdown([bitmain, questions])
  }, [])

  return (
    <MinerLayout>
      <div className='documentation-container'>
        <ReactMarkdown renderers={renderers} plugins={[gfm]}>
          {intro}
        </ReactMarkdown>
        <EnvDropdown
          width='180px'
          options={[
            {
              value: 'bitmain-antminerka3',
              label: 'Bitmain Antminer KA3',
              function: () => fetchAndSetMarkdown([bitmain, questions])
            },
            {
              value: 'goldshell-kdmax',
              label: 'Goldshell KD MAX',
              function: () => fetchAndSetMarkdown([goldshell, questions])
            },
            {
              value: 'ibelink-bmk1max',
              label: 'iBeLink BM-K1Max',
              function: () => fetchAndSetMarkdown([ibelink, questions])
            }
          ]}
        />
        <ReactMarkdown renderers={renderers} plugins={[gfm, toc]}>
          {doc}
        </ReactMarkdown>
      </div>
    </MinerLayout>
  )
}

export default Documentation
