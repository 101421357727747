import { useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import Captcha from '../../components/Captcha'
import AuthLayout from '../../components/Layouts/AuthLayout'

import './styles.scss'

import { signUp, createUserProfile } from '../../services/user'

const SignUp = () => {
  const [name, setName] = useState('')
  const [org, setOrg] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [token, setToken] = useState(null)

  const validateInput = () => {
    if (password !== confirmPassword) {
      NotificationManager.error(
        'Please try again.',
        'The passwords don’t match.'
      )
      return false
    }

    return true
  }

  const handleSignUp = async event => {
    event.preventDefault()

    if (!validateInput()) return

    if (!token) {
      NotificationManager.error('You must verify that you’re human.')
      return
    }

    document.body.style.cursor = 'wait'

    try {
      const { uid } = await signUp(email, password)

      const profile = {
        uid,
        name,
        org,
        email
      }

      await createUserProfile(profile, token)

      window.location.reload()
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <AuthLayout>
      <div className='signup-container'>
        <form onSubmit={handleSignUp}>
          <input
            required
            type='text'
            placeholder='Your full name'
            name='name'
            id='name-signup'
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <input
            required
            type='text'
            placeholder='Your organization name'
            name='org'
            id='org-signup'
            value={org}
            onChange={event => setOrg(event.target.value)}
          />
          <input
            required
            type='email'
            placeholder='Your email address'
            name='email'
            id='email-signup'
            value={email}
            autoComplete='username'
            onChange={event => setEmail(event.target.value)}
          />
          <input
            required
            pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
            title='A letter, a number, and a total of 8 to 24 characters'
            type='password'
            placeholder='Password'
            name='password'
            id='password-signup'
            value={password}
            autoComplete='new-password'
            onChange={event => setPassword(event.target.value)}
          />
          <input
            required
            pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
            title='A letter, a number, and a total of 8 to 24 characters'
            type='password'
            placeholder='Password again'
            name='confirmPassword'
            id='confirmpassword-signup'
            value={confirmPassword}
            autoComplete='new-password'
            onChange={event => setConfirmPassword(event.target.value)}
          />
          <div className='terms'>
            <label htmlFor='checkbox-signup' className='label-container'>
              <input required id='checkbox-signup' type='checkbox' />
              <span className='checkmark' />
              <span>
                By checking, you agree to{' '}
                <Link to='/terms' target='_blank'>
                  NoPool’s terms
                </Link>
              </span>
            </label>
          </div>
          <Captcha setToken={setToken} />
          <button type='submit' className='btn-submit'>
            Register
          </button>
        </form>
        <div className='login'>
          <span>
            Already have an account<em>?</em> <Link to='/login'>Log in</Link>
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}

export default SignUp
