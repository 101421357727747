import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

import './styles.scss'

const workerStyles = {
  ...customStyles,
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'",
    display: 'inline-block'
  })
}

const WorkerDropdown = ({ options, defaultOption, walletAddress, width }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const history = useHistory()

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  return (
    <div className='workers'>
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        value={selectedOption}
        onChange={option => {
          setSelectedOption(option)
          history.push(`${walletAddress}.${option.value}`)
        }}
        options={options}
        isSearchable={false}
        hideSelectedOptions
        theme={customTheme}
        styles={workerStyles}
        width={width}
        isDisabled={options.length < 2}
      />
    </div>
  )
}

export default WorkerDropdown
