import './styles.scss'

const BalanceBoard = ({ poolFee }) => (
  <div className='balance'>
    <div className='title'>Pay-per-share fee (beta)</div>
    <div className='title short'>PPS fee (beta)</div>
    <div className='value'>
      {poolFee.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </div>
  </div>
)

export default BalanceBoard
