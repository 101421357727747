import { memo, useMemo } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'

import ExternalLink from '../../../../components/SVGs/ExternalLink'

import formatCurrency from '../../../../utils/currency'

import './styles.scss'

const PayoutsTable = memo(
  ({ data }) => {
    const columns = useMemo(
      () => [
        {
          header: 'Date',
          shortHeader: 'Date',
          id: 'time-payout',
          accessor: row => row.date
        },
        {
          header: 'Previous balance',
          shortHeader: 'Previous',
          id: 'previous-payout',
          accessor: row =>
            `${formatCurrency(row.currentBalance + row.payout, 2, 4)} KDA`
        },
        {
          header: 'Amount paid',
          shortHeader: 'Paid',
          id: 'amount-payout',
          accessor: row => {
            const { receiptUrl, payout } = row

            return receiptUrl ? (
              <div>
                {formatCurrency(payout, 2, 4)} KDA
                <a
                  className='externalLink'
                  href={receiptUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ExternalLink width={12} height={12} />
                </a>
              </div>
            ) : (
              `${formatCurrency(payout, 2, 4)} KDA`
            )
          }
        },
        {
          header: 'Remaining balance',
          shortHeader: 'Remaining',
          id: 'balance-payout',
          accessor: row => `${formatCurrency(row.currentBalance, 2, 4)} KDA`
        }
      ],
      []
    )

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: 0,
          pageSize: 5
        }
      },
      useSortBy,
      usePagination
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      state: { pageIndex }
    } = tableInstance

    return (
      <div className='payouts-table'>
        <p>Reward payouts</p>
        <div className='table-wrap'>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='label'>{column.render('header')}</span>
                      <span className='label short'>
                        {column.render('shortHeader')}
                      </span>
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ↓'
                            : ' ↑'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length !== 0 ? (
                page.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='pagination'>
          <button
            type='button'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            type='button'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            type='button'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          <button
            type='button'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
          <br />
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length ? pageOptions.length : 1}
            </strong>
          </span>
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) {
      return false
    }

    return true
  }
)

export default PayoutsTable
