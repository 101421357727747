import { useContext, useState, useEffect } from 'react'
import { NotificationManager } from 'react-notifications'

import Header from '../../components/Navbar/Header'
import SearchBox from './components/SearchBox'
import AdminTools from './components/AdminTools'
import Infobox from '../../components/Infobox'
import DatePicker from './components/DatePicker'
import MonthlyDropdown from '../../components/Dropdowns/MonthlyDropdown'
import Timestamp from './components/Timestamp'
import BalanceBoard from './components/BalanceBoard'
import MetricsBoard from './components/MetricsBoard'
import Spinner from '../../components/Spinner'
import ChartDropdown from '../../components/Dropdowns/ChartDropdown'
import Chart from './components/Chart'
import NoData from './components/NoData'
import MetricsTable from './components/MetricsTable'
import PayoutsTable from './components/PayoutsTable'
import Copyright from '../../components/Copyright'

import AuthContext from '../../contexts/AuthContext'
import {
  today,
  yesterday,
  startOfMonth,
  endOfMonth,
  formatReportDate
} from '../../utils/dates'
import {
  fetchPathData,
  fetchNetworkData,
  fetchPpsData,
  fetchBlocks,
  fetchAllPayoutsData
} from '../../services/dashboard'
import {
  convertJsonToArray,
  filterDataByDate,
  processMetrics,
  populateChartData
} from './utils/metrics'
import processWallet from './utils/wallet'

import './styles.scss'

const Dashboard = () => {
  const { uid, isAdmin } = useContext(AuthContext)
  const [showSpinner, setShowSpinner] = useState(true)
  const [startDate, setStartDate] = useState(yesterday())
  const [endDate, setEndDate] = useState(today())
  const [payoutsData, setPayoutsData] = useState([])
  const [rawDashboardData, setRawDashboardData] = useState({})
  const [rawPoolBlocks, setRawPoolBlocks] = useState({})
  const [convertedDashboardData, setConvertedDashboardData] = useState([])
  const [filteredPoolBlocks, setFilteredPoolBlocks] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [chartData, setChartData] = useState({})
  const [chartIndex, setChartIndex] = useState(0)
  const [poolBlocks, setPoolBlocks] = useState([])
  const [poolFee, setPoolFee] = useState(0)
  const [timestamp, setTimestamp] = useState('')
  const [monthlyOptions, setMonthlyOptions] = useState([])
  const [metrics, setMetrics] = useState({
    poolHashrate: 0,
    networkHashrate: 0,
    poolRewards: 0,
    coinPrice: 0,
    poolMiners: 0,
    poolWorkers: 0
  })

  useEffect(() => {
    const fetchAndSetDashboardData = async () => {
      try {
        const address =
          'ed421ce6c22e212149ab53e3d9ddd9a7a1a5a793a35c4e28f8b253625ce0e604/workers/*'
        const [data, networkData, ppsData, blocks] = await Promise.all([
          fetchPathData('wallets/kda', address),
          fetchNetworkData(),
          fetchPpsData(),
          fetchBlocks()
        ])
        const rawData = JSON.parse(JSON.stringify(networkData))
        const rawBlocks = JSON.parse(JSON.stringify(networkData))

        if (!data || !networkData || !ppsData || !blocks) {
          setShowSpinner(false)
          return
        }

        const days = Object.keys(networkData)
        const lastDay = days.length - 1

        days.forEach((date, i) => {
          const dailyNetworkData = networkData[date]
          const dailyData = data[date] || {}
          const times = Object.keys(dailyNetworkData)
          const lastTime = times.length - 1

          times.forEach((time, j) => {
            if (i === lastDay && j === lastTime) {
              delete dailyNetworkData[time]
              return
            }

            const incrementalNetworkData = dailyNetworkData[time]
            const incrementalData = dailyData[time] || {}

            incrementalNetworkData.utcTimestamp =
              incrementalNetworkData.utcTimestamp || '00:00:00'
            incrementalNetworkData.networkDifficulty =
              incrementalNetworkData.difficulty || 0
            incrementalNetworkData.networkHashCount =
              incrementalNetworkData.hashCount || 0
            incrementalNetworkData.price = incrementalNetworkData.usdPrice || 0

            incrementalNetworkData.elapsedTime =
              incrementalData.elapsedSeconds &&
              incrementalData.elapsedSeconds >= 55
                ? incrementalData.elapsedSeconds
                : 60
            incrementalNetworkData.hashCount = incrementalData.hashCount || 0
            incrementalNetworkData.reward = incrementalData.kdaReward || 0
            incrementalNetworkData.minerCount = incrementalData.walletCount || 0
            incrementalNetworkData.workerCount =
              (incrementalData.authorizedCount || 0) +
              (incrementalData.unauthorizedCount || 0)

            incrementalNetworkData.fee = ppsData.fee
          })

          rawData[date].data = dailyNetworkData
          rawBlocks[date].blocks = blocks[date] || {}
        })

        setRawDashboardData(rawData)
        setRawPoolBlocks(rawBlocks)
      } catch (error) {
        NotificationManager.error(
          error.message,
          'Data failed to load – please try again.'
        )

        const Sentry = await import('@sentry/react')
        Sentry.captureException(error)
      }
    }

    fetchAndSetDashboardData()
  }, [])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const convertedData = convertJsonToArray(rawDashboardData)
      const convertedBlocks = convertJsonToArray(rawPoolBlocks)

      const filteredData = filterDataByDate(convertedData, startDate, endDate)
      const filteredBlocks = filterDataByDate(
        convertedBlocks,
        startDate,
        endDate
      )

      const explodedData = filteredData.reduce((data, rawData) => {
        const dailyData = rawData.data

        Object.keys(dailyData).forEach(time => {
          const incrementalData = dailyData[time]
          incrementalData.date = rawData.date
          incrementalData.time = time

          data.push(incrementalData)
        })

        return data
      }, [])

      setConvertedDashboardData(convertedData)
      setFilteredPoolBlocks(filteredBlocks)
      setDashboardData(explodedData)
      setShowSpinner(false)
    }
  }, [rawDashboardData, rawPoolBlocks, startDate, endDate])

  useEffect(() => {
    if (dashboardData.length !== 0) {
      const processedMetrics = processMetrics(dashboardData)

      setMetrics({
        poolHashrate: processedMetrics.reducedAvgPoolHashrate,
        networkHashrate: processedMetrics.reducedAvgNetworkHashrate,
        poolRewards: processedMetrics.reducedTotPoolRewards,
        coinPrice: processedMetrics.reducedAvgCoinPrice,
        poolMiners: processedMetrics.reducedAvgPoolMiners,
        poolWorkers: processedMetrics.reducedAvgPoolWorkers
      })
    }
  }, [dashboardData])

  useEffect(() => {
    if (dashboardData.length !== 0) {
      const filledChartData = populateChartData(dashboardData, chartIndex)

      setChartData(filledChartData)
    }
  }, [dashboardData, chartIndex])

  useEffect(() => {
    if (filteredPoolBlocks.length !== 0) {
      const explodedBlocks = filteredPoolBlocks
        .reverse()
        .reduce((blocks, rawBlocks) => {
          const dailyBlocks = rawBlocks.blocks

          Object.keys(dailyBlocks)
            .reverse()
            .forEach(time => {
              const incrementalBlock = dailyBlocks[time]

              incrementalBlock.time = `${rawBlocks.isoDate}T${time}`
              blocks.push(incrementalBlock)
            })

          return blocks
        }, [])

      setPoolBlocks(explodedBlocks)
    }
  }, [filteredPoolBlocks])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const dailyData = Object.values(rawDashboardData)
      const lastDay = dailyData[dailyData.length - 1]
      const incrementalData = Object.values(lastDay.data)
      const lastIncrement = incrementalData[incrementalData.length - 1]
      const { fee, utcTimestamp } = lastIncrement

      setPoolFee(fee)
      setTimestamp(utcTimestamp)
    }
  }, [rawDashboardData])

  useEffect(() => {
    if (convertedDashboardData.length !== 0) {
      const fetchWalletData = async () => {
        try {
          const payouts = isAdmin
            ? await fetchAllPayoutsData()
            : { payoutsData: [], totalPayout: 0 }
          const processedWallet = processWallet(convertedDashboardData, payouts)

          setPayoutsData(processedWallet.payoutsWithBalance)
        } catch (error) {
          NotificationManager.error(
            error.message,
            'Payouts failed to load – please try again.'
          )

          const Sentry = await import('@sentry/react')
          Sentry.captureException(error)
        }
      }

      fetchWalletData()
    }
  }, [uid, isAdmin, convertedDashboardData])

  useEffect(() => {
    if (Object.keys(rawDashboardData).length !== 0) {
      const yearMonth = []

      Object.keys(rawDashboardData).forEach(date => {
        const slicedDate = date.slice(0, 7)

        const dateIndex = yearMonth.indexOf(slicedDate)

        if (dateIndex === -1) {
          yearMonth.push(slicedDate)
        }
      })

      const options = []

      yearMonth.forEach(month => {
        const formattedDate = formatReportDate(month)

        options.push({
          value: formattedDate,
          label: formattedDate,
          function: () => {
            const firstDay = startOfMonth(month)
            const lastDay = endOfMonth(month)

            setStartDate(firstDay)
            setEndDate(lastDay)
          }
        })
      })

      setMonthlyOptions(options)
    }
  }, [rawDashboardData])

  let content
  let hasNoData

  if (showSpinner) {
    content = <Spinner />
    hasNoData = true
  } else if (dashboardData.length > 0) {
    const chartOptions = isAdmin
      ? [
          {
            value: 'pool-hashrate',
            label: 'NoPool hashrate',
            function: () => setChartIndex(0)
          },
          {
            value: 'network-hashrate',
            label: 'Kadena hashrate',
            function: () => setChartIndex(1)
          },
          {
            value: 'pool-rewards',
            label: 'NoPool rewards',
            function: () => setChartIndex(2)
          },
          {
            value: 'coin-price',
            label: 'Kadena price',
            function: () => setChartIndex(3)
          },
          {
            value: 'pool-miners',
            label: 'NoPool miners',
            function: () => setChartIndex(4)
          },
          {
            value: 'pool-workers',
            label: 'NoPool workers',
            function: () => setChartIndex(5)
          }
        ]
      : [
          {
            value: 'pool-hashrate',
            label: 'NoPool hashrate',
            function: () => setChartIndex(0)
          },
          {
            value: 'network-hashrate',
            label: 'Kadena hashrate',
            function: () => setChartIndex(1)
          },
          {
            value: 'pool-rewards',
            label: 'NoPool rewards',
            function: () => setChartIndex(2)
          },
          {
            value: 'coin-price',
            label: 'Kadena price',
            function: () => setChartIndex(3)
          }
        ]

    content = (
      <>
        <ChartDropdown width='180px' options={chartOptions} />
        <Chart data={chartData} index={chartIndex} />
        <MetricsTable data={poolBlocks} />
        {isAdmin && <PayoutsTable data={[...payoutsData].reverse()} />}
      </>
    )
  } else {
    content = <NoData />
    hasNoData = true
  }

  return (
    <>
      <Header />
      <div className='dashboard-container'>
        <SearchBox placeholder='Wallet address or worker name' />
        {isAdmin && (
          <div className='admin-tools'>
            <AdminTools />
          </div>
        )}
        <Infobox />
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          firstDate={convertedDashboardData[0]?.date}
        />
        {isAdmin && (
          <MonthlyDropdown
            width='180px'
            options={[...monthlyOptions].reverse()}
          />
        )}
        <Timestamp timestamp={timestamp} />
        <BalanceBoard poolFee={poolFee} />
        <MetricsBoard
          poolHashrate={metrics.poolHashrate}
          networkHashrate={metrics.networkHashrate}
          poolRewards={metrics.poolRewards}
          coinPrice={metrics.coinPrice}
          poolMiners={metrics.poolMiners}
          poolWorkers={metrics.poolWorkers}
        />
        {content}
      </div>
      <Copyright hasNoData={hasNoData} />
    </>
  )
}

export default Dashboard
