import DesktopLogo from '../SVGs/DesktopLogo'

import './styles.scss'

const LogoTitle = () => (
  <div className='logo-title'>
    <a href='/'>
      <DesktopLogo width={220} height={45} />
    </a>
  </div>
)

export default LogoTitle
