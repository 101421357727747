import { Line } from 'react-chartjs-2'

import formatCurrency from '../../../../utils/currency'
import { formatHashrate } from '../../utils/metrics'

import './styles.scss'

const getCSSVariable = variable =>
  getComputedStyle(document.documentElement).getPropertyValue(variable)

const Chart = ({ data, index }) => {
  const formatLabel = (label, shouldAbridge) => {
    let formattedLabel

    switch (index) {
      case 2:
        formattedLabel = `${formatCurrency(
          label,
          2,
          shouldAbridge ? 2 : 4
        )} KDA`
        break
      case 3:
        formattedLabel = `$${formatCurrency(label, 2, 2)}`
        break
      case 4:
        formattedLabel = `${label.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1
        })} miner${label === 1 ? '' : 's'}`
        break
      case 5:
        formattedLabel = `${label.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1
        })} worker${label === 1 ? '' : 's'}`
        break
      default:
        formattedLabel = formatHashrate(label, 0, 1)
    }

    return formattedLabel
  }

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontFamily: getCSSVariable('--font-primary'),
            fontColor: getCSSVariable('--text-secondary'),
            fontSize: 10,
            fontWeight: 500,
            padding: 5
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            fontFamily: getCSSVariable('--font-primary'),
            fontColor: getCSSVariable('--text-secondary'),
            fontSize: 10,
            fontWeight: 500,
            callback: value => formatLabel(value, true),
            padding: 5
          }
        }
      ]
    },
    elements: {
      point: {
        backgroundColor: getCSSVariable('--blue-primary'),
        radius: 1,
        hoverRadius: 4
      },
      line: {
        borderWidth: 3,
        borderColor: getCSSVariable('--blue-primary'),
        fill: false
      }
    },
    tooltips: {
      titleFontFamily: getCSSVariable('--font-primary'),
      displayColors: false,
      bodyFontFamily: getCSSVariable('--font-primary'),
      callbacks: {
        title(tooltipData) {
          return tooltipData[0].xLabel
        },
        label: tooltipData => formatLabel(tooltipData.yLabel || 0)
      }
    }
  }

  return (
    <div className='chart'>
      <Line data={data} options={chartOptions} />
    </div>
  )
}

export default Chart
