import moment from 'moment'
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  confirmPasswordReset as resetPassword,
  updatePassword
} from 'firebase/auth'
import { getDatabase, ref, onValue, set } from 'firebase/database'

import firebaseApp from '../utils/firebase'
import triggerFunction from '../utils/serverless'

const auth = getAuth(firebaseApp)
const db = getDatabase(firebaseApp)

export const confirmNewEmail = async (uid, newEmail) => {
  try {
    const response = await triggerFunction('miner/update', {
      params: {
        uid,
        newEmail
      }
    })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const confirmRegistration = async (uid, token) => {
  try {
    const response = await triggerFunction('miner/confirmation', {
      params: {
        uid,
        token
      }
    })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const confirmPasswordReset = async (email, token) => {
  try {
    const response = await triggerFunction('miner/reset', {
      params: {
        email,
        token
      }
    })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const isLoggedIn = () =>
  new Promise(resolve => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(user.uid)
      } else {
        resolve(null)
      }
    })
  })

export const getUserProfile = uid =>
  new Promise((resolve, reject) => {
    try {
      const minersRef = ref(db, `miners/${uid}`)

      onValue(minersRef, snapshot => {
        const data = snapshot.val()

        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (error) {
    throw new Error(error)
  }
}

export const logout = async () => {
  try {
    await signOut(auth)
  } catch (error) {
    throw new Error(error)
  }
}

export const signUp = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password)

    return user
  } catch (error) {
    throw new Error(error)
  }
}

export const createUserProfile = async ({ uid, name, org, email }, token) => {
  try {
    const minersRef = ref(db, `miners/${uid}`)

    const response = await set(minersRef, {
      name,
      org,
      email,
      status: 'created',
      utcTimestamp: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    })

    await confirmRegistration(uid, token)

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export const completePasswordReset = async (code, newPassword) => {
  try {
    await resetPassword(auth, code, newPassword)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateUserPassword = async newPassword => {
  try {
    const user = auth.currentUser

    await updatePassword(user, newPassword)
  } catch (error) {
    throw new Error(error)
  }
}
