import { useState, useEffect } from 'react'
import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import {
  today,
  yesterday,
  lastWeek,
  lastMonth,
  lastYear,
  isAfter,
  isSame,
  convertFirstDate
} from '../../../../utils/dates'

import './styles.scss'

const DatePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  firstDate
}) => {
  const [focusedInput, setFocusedInput] = useState(null)
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 899px)')

    if (mediaQuery.matches) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const handlePickedDate = pickedDate => {
    const start = pickedDate.startDate
    const end = pickedDate.endDate

    const filteredStartDate = isAfter(start, today()) ? today() : start

    let filteredEndDate = isAfter(end, today()) ? today() : end

    if (!end) {
      filteredEndDate = filteredStartDate
    }

    setStartDate(filteredStartDate)
    setEndDate(filteredEndDate)
  }

  const renderDateRange = () => (
    <div className='customInfoPanel'>
      <button
        type='button'
        onClick={() => {
          setStartDate(today())
          setEndDate(today())
        }}
        className={
          isSame(startDate, today()) &&
          isSame(endDate, today()) &&
          'selectedButton'
        }
      >
        Today
      </button>
      <button
        type='button'
        onClick={() => {
          setStartDate(yesterday())
          setEndDate(yesterday())
        }}
        className={
          isSame(startDate, yesterday()) &&
          isSame(endDate, yesterday()) &&
          'selectedButton'
        }
      >
        Yesterday
      </button>
      <button
        type='button'
        onClick={() => {
          setStartDate(lastWeek())
          setEndDate(yesterday())
        }}
        className={
          isSame(startDate, lastWeek()) &&
          isSame(endDate, yesterday()) &&
          'selectedButton'
        }
      >
        Last week
      </button>
      <button
        type='button'
        onClick={() => {
          setStartDate(lastMonth())
          setEndDate(yesterday())
        }}
        className={
          isSame(startDate, lastMonth()) &&
          isSame(endDate, yesterday()) &&
          'selectedButton'
        }
      >
        Last month
      </button>
      <button
        type='button'
        onClick={() => {
          setStartDate(lastYear())
          setEndDate(yesterday())
        }}
        className={
          isSame(startDate, lastYear()) &&
          isSame(endDate, yesterday()) &&
          'selectedButton'
        }
      >
        Last year
      </button>
      <button
        type='button'
        onClick={() => {
          setStartDate(convertFirstDate(firstDate))
          setEndDate(yesterday())
        }}
        className={
          isSame(startDate, convertFirstDate(firstDate)) &&
          isSame(endDate, yesterday()) &&
          'selectedButton'
        }
      >
        All time
      </button>
    </div>
  )

  return (
    <div className='date-picker'>
      <DateRangePicker
        startDate={startDate}
        startDateId='startDateId'
        endDate={endDate}
        endDateId='endDateId'
        onDatesChange={handlePickedDate}
        focusedInput={focusedInput}
        onFocusChange={input => setFocusedInput(input)}
        minimumNights={0}
        isOutsideRange={() => false}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        verticalHeight={650}
        renderCalendarInfo={renderDateRange}
        calendarInfoPosition='top'
        hideKeyboardShortcutsPanel
      />
    </div>
  )
}

export default DatePicker
