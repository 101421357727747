import { formatUpdateTime } from '../../../../utils/dates'

import './styles.scss'

const Timestamp = ({ timestamp }) =>
  timestamp ? (
    <time className='timestamp'>
      {`Updated: ${formatUpdateTime(timestamp)} UTC`}
    </time>
  ) : null

export default Timestamp
