import Copyright from '../../Copyright'
import LogoTitle from '../../LogoTitle'
import Switch from '../../Theme/Switch'

import './styles.scss'

const AuthLayout = ({ hasHeader, children }) => (
  <>
    <div className='auth-container'>
      <div className='auth'>
        {hasHeader || (
          <>
            <Switch />
            <LogoTitle />
          </>
        )}
        {children}
      </div>
    </div>
    <Copyright />
  </>
)

export default AuthLayout
