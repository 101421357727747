import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyCkbiT03Ad3KnPJ5sq7QW-Uq9ojXRXIX4w',
  authDomain: 'nopool.firebaseapp.com',
  databaseURL: 'https://nopool-default-rtdb.firebaseio.com',
  projectId: 'nopool',
  storageBucket: 'nopool.appspot.com',
  messagingSenderId: '663332542519',
  appId: '1:663332542519:web:86ed623b36c831f25d87b2',
  measurementId: 'G-P06J9XJWPL'
}

const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp
