import moment from 'moment'
import 'moment-timezone'

export const today = () => moment().utc().startOf('day')

export const yesterday = () => moment().utc().subtract(1, 'days').startOf('day')

export const lastWeek = () => moment().utc().subtract(1, 'weeks').startOf('day')

export const lastMonth = () =>
  moment().utc().subtract(1, 'months').startOf('day')

export const lastQuarter = () =>
  moment().utc().subtract(1, 'quarters').startOf('day')

export const lastYear = () => moment().utc().subtract(1, 'years').startOf('day')

export const isoToLocalDate = date => moment(date).format('YYYY/MM/DD')

export const isoToLocalTime = time =>
  moment(time, moment.HTML5_FMT.TIME_SECONDS).local().format('h:mma')

export const isoToPacificTime = time =>
  moment(time, moment.HTML5_FMT.TIME_SECONDS)
    .tz('America/Los_Angeles')
    .format('h:mma')

export const formatChartDate = dateString =>
  moment(dateString, 'YYYY/MM/DD').format('MM/DD/YYYY')

export const startOfMonth = dateString =>
  moment(dateString, 'YYYY-MM').startOf('month')

export const endOfMonth = dateString =>
  moment(dateString, 'YYYY-MM').endOf('month')

export const formatReportDate = dateString =>
  moment(dateString, 'YYYY-MM').format('MMMM YYYY')

export const formatUpdateTime = timeString =>
  moment(timeString, moment.HTML5_FMT.TIME_SECONDS).format('h:mma')

export const formatChartTime = timeString =>
  moment(timeString, moment.HTML5_FMT.TIME_SECONDS).format('h:mma')

export const formatTableTime = timeString =>
  moment(timeString, moment.ISO_8601).format('YYYY-MM-DD HH:mm:ss')

export const isAfter = (date, limit) => moment(date).isAfter(limit, 'day')

export const isSame = (first, second) => moment(first).isSame(second)

export const convertFirstDate = first => moment(first, 'YYYY/MM/DD')
