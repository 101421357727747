import * as Sentry from '@sentry/react'

const initSentry = () => {
  const isProd = process.env.NODE_ENV === 'production'

  if (isProd) {
    Sentry.init({
      dsn: 'https://aa28f378543e4d7c8d42b90e95bd6e53@o428832.ingest.sentry.io/4504116121436160'
    })
  }
}

export default initSentry
