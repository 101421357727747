import { getDatabase, ref, onValue } from 'firebase/database'

import firebaseApp from '../utils/firebase'

const db = getDatabase(firebaseApp)

export const fetchWalletAddresses = () =>
  new Promise((resolve, reject) => {
    const ignoredAddresses = []

    try {
      const walletsRef = ref(db, 'wallets/kda')

      onValue(walletsRef, snapshot => {
        const walletsData = snapshot.val()

        const addresses = Object.keys(walletsData).filter(
          address => !ignoredAddresses.includes(address)
        )

        const annotatedAddresses = addresses.map(address => {
          const { minerId } = walletsData[address]

          return {
            address,
            minerId
          }
        })

        resolve(annotatedAddresses)
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchPathData = (path, uid) =>
  new Promise((resolve, reject) => {
    try {
      const pathRef = ref(db, `${path}/${uid}`)

      onValue(pathRef, snapshot => {
        const pathData = snapshot.val()

        resolve(pathData)
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchPayoutsData = uid =>
  new Promise((resolve, reject) => {
    const payoutsData = []
    let totalPayout = 0

    try {
      const payoutsRef = ref(db, `payouts/kda/${uid}`)

      onValue(payoutsRef, snapshot => {
        if (snapshot.val() !== null) {
          totalPayout = Object.values(snapshot.val()).reduce(
            (sum, element) => sum + parseFloat(element.kda),
            0
          )

          snapshot.forEach(data => {
            const date = data.key
            const payout = parseFloat(data.val().kda)
            const { receiptUrl } = data.val()

            const payoutObject = {
              date,
              payout,
              receiptUrl
            }

            payoutsData.push(payoutObject)
          })
        }

        const data = {
          payoutsData,
          totalPayout
        }

        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchAllPayoutsData = () =>
  new Promise((resolve, reject) => {
    const payoutsData = []
    let totalPayout = 0

    try {
      const payoutsRef = ref(db, 'payouts/kda')

      onValue(payoutsRef, snapshot => {
        snapshot.forEach(partner => {
          totalPayout += Object.values(partner.val()).reduce(
            (sum, element) => sum + parseFloat(element.kda),
            0
          )

          partner.forEach(data => {
            const date = data.key
            const payout = parseFloat(data.val().kda)
            const { receiptUrl } = data.val()

            const payoutObject = {
              date,
              payout,
              receiptUrl
            }

            const payoutIndex = payoutsData
              .map(payouts => payouts.date)
              .indexOf(date)

            if (payoutIndex === -1) {
              payoutsData.push(payoutObject)
            } else {
              payoutsData[payoutIndex].payout += payout
            }
          })
        })

        payoutsData.sort((a, b) => new Date(a.date) - new Date(b.date))

        const data = {
          payoutsData,
          totalPayout
        }

        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchNetworkData = () =>
  new Promise((resolve, reject) => {
    try {
      onValue(ref(db, 'networks/kda'), snapshot => {
        resolve(snapshot.val())
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchPpsData = () =>
  new Promise((resolve, reject) => {
    try {
      onValue(ref(db, 'ppses/kda'), snapshot => {
        resolve(snapshot.val())
      })
    } catch (error) {
      reject(error)
    }
  })

export const fetchBlocks = () =>
  new Promise((resolve, reject) => {
    try {
      onValue(ref(db, 'blocks/kda'), snapshot => {
        resolve(snapshot.val())
      })
    } catch (error) {
      reject(error)
    }
  })
