import { useContext } from 'react'
import NotificationManager from 'react-notifications'

import NavDropdown from '../../Dropdowns/NavDropdown'
import NavigationItem from '../NavigationItem'
import Switch from '../../Theme/Switch'

import AuthContext from '../../../contexts/AuthContext'
import { logout } from '../../../services/user'

import '../NavigationItem/styles.scss'
import './styles.scss'

const NavigationItems = ({ email, clicked, drawer }) => {
  const authData = useContext(AuthContext)
  const { isAdmin } = authData

  const navOptions = isAdmin
    ? [
        { value: 'profile', label: 'Profile' },
        { value: 'logout', label: 'Logout' }
      ]
    : [
        { value: 'profile', label: 'Profile' },
        { value: 'logout', label: 'Logout' }
      ]

  const handleSignOut = async () => {
    try {
      await logout()
      window.location.reload()
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Logout failed – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }
  }

  return (
    <div className='navigation-items'>
      <NavigationItem link='/' clicked={clicked}>
        Home
      </NavigationItem>

      <div className='navigation-item'>
        <a
          href='https://kda.beta.nopool.com:1789/chainweb/0.0/mainnet01/cut'
          target='_blank'
          rel='noopener noreferrer'
        >
          Node status
        </a>
      </div>

      <NavigationItem link='/help' clicked={clicked}>
        Help
      </NavigationItem>

      {!email && (
        <NavigationItem link='/login' clicked={clicked}>
          Login
        </NavigationItem>
      )}

      {email && (
        <div className='desktop-only'>
          <NavDropdown
            width='280px'
            options={navOptions}
            email={{ value: email, label: email }}
            handleSignOut={handleSignOut}
          />
        </div>
      )}

      {email && (
        <div className='mobile-only'>
          <NavigationItem link='/profile' clicked={clicked}>
            Profile
          </NavigationItem>
          <NavigationItem link='/logout' clicked={handleSignOut}>
            Logout
          </NavigationItem>
        </div>
      )}

      <Switch id={drawer ? 'switch-drawer' : 'switch'} />
    </div>
  )
}

export default NavigationItems
