import './styles.scss'

const Input = ({
  required,
  shouldValidatePassword,
  type,
  name,
  placeholder,
  value,
  disabled,
  onValueChange,
  autoComplete
}) => (
  <input
    required={required}
    pattern={shouldValidatePassword && '(?=.*[A-Za-z])(?=.*\\d).{8,24}'}
    title={
      shouldValidatePassword &&
      'A letter, a number, and a total of 8 to 24 characters'
    }
    type={type}
    name={name}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    onChange={event => onValueChange(event.target.value)}
    autoComplete={autoComplete}
  />
)

export default Input
