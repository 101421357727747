import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

const ChartDropdown = ({ width, options }) => (
  <Select
    className='react-select-container'
    classNamePrefix='react-select'
    defaultValue={options[0]}
    onChange={option => option.function()}
    options={options}
    isSearchable={false}
    hideSelectedOptions
    theme={customTheme}
    styles={customStyles}
    width={width}
  />
)

export default ChartDropdown
