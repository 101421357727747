import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

const navStyles = {
  ...customStyles,
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'",
    marginLeft: 10
  })
}

const NavDropdown = ({ width, options, email, handleSignOut }) => {
  const history = useHistory()

  const functions = label => {
    switch (label) {
      case 'Admin':
        history.push('/admin')
        break
      case 'Profile':
        history.push('/profile')
        break
      case 'Logout':
        handleSignOut()
        break
      default:
    }
  }

  return (
    <Select
      className='react-select-container'
      classNamePrefix='react-select'
      value={email}
      onChange={option => functions(option.label)}
      options={options}
      isSearchable={false}
      hideSelectedOptions
      theme={customTheme}
      styles={navStyles}
      width={width}
    />
  )
}

export default NavDropdown
