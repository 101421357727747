import Logo from '../Logo'
import NavigationItems from '../NavigationItems'
import DrawerToggle from '../DrawerToggle'

import './styles.scss'

const Toolbar = ({ drawerToggleClicked, email }) => (
  <header className='toolbar'>
    <div className='logo-toolbar'>
      <Logo />
    </div>
    <nav className='desktop-only'>
      <NavigationItems email={email} />
    </nav>
    <DrawerToggle clicked={drawerToggleClicked} />
  </header>
)

export default Toolbar
