import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import { completePasswordReset } from '../../../../services/user'

const PasswordForm = ({ code }) => {
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const validateInput = () => {
    if (password !== confirmPassword) {
      NotificationManager.error(
        'Please re-enter the passwords.',
        'The passwords you entered don’t match.'
      )
      return false
    }

    return true
  }

  const handlePasswordReset = async event => {
    event.preventDefault()

    if (!validateInput()) return

    document.body.style.cursor = 'wait'

    try {
      await completePasswordReset(code, password)
      NotificationManager.success('Your password was successfully reset.')
      history.push('/login')
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <form onSubmit={handlePasswordReset}>
      <input
        required
        pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
        title='A letter, a number, and a total of 8 to 24 characters'
        type='password'
        placeholder='New password'
        id='password-resetpassword'
        name='password'
        value={password}
        autoComplete='new-password'
        onChange={event => setPassword(event.target.value)}
      />
      <input
        required
        pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
        title='A letter, a number, and a total of 8 to 24 characters'
        type='password'
        placeholder='New password again'
        id='confirmpassword-resetpassword'
        name='confirmPassword'
        value={confirmPassword}
        autoComplete='new-password'
        onChange={event => setConfirmPassword(event.target.value)}
      />
      <button type='submit' className='btn-submit'>
        Reset password
      </button>
    </form>
  )
}

export default PasswordForm
