import { useState } from 'react'
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import AuthLayout from '../../components/Layouts/AuthLayout'

import { login } from '../../services/user'
import './styles.scss'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async event => {
    event.preventDefault()

    document.body.style.cursor = 'wait'

    try {
      await login(email, password)
      window.location.reload()
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Login failed – please try again.'
      )

      const Sentry = await import('@sentry/react')
      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <AuthLayout>
      <div className='login-container'>
        <form onSubmit={handleLogin}>
          <input
            required
            type='email'
            placeholder='Your email'
            id='email-login'
            name='email'
            value={email}
            autoComplete='username'
            onChange={event => setEmail(event.target.value)}
          />
          <input
            required
            type='password'
            placeholder='Password'
            id='password-login'
            name='password'
            value={password}
            autoComplete='current-password'
            onChange={event => setPassword(event.target.value)}
          />
          <button type='submit' className='btn-submit'>
            Log in
          </button>
        </form>
        <div className='reset-password'>
          <Link to='/reset'>Reset your password</Link>
        </div>
        <div className='register'>
          <span>
            Don’t have an account<em>?</em>{' '}
            <Link to='/registration'>Register</Link> or <Link to='/'>skip</Link>
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Login
