import {
  isoToLocalDate,
  formatChartDate,
  formatChartTime
} from '../../../utils/dates'

const reduceAvgWorkerHashrate = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return (
          sum +
          (element.elapsedTime ? element.hashCount / element.elapsedTime : 0)
        )
      }, 0) / incrementCount
    : 0
}

const reduceTotValidShares = data => {
  return data.reduce((sum, element) => {
    return sum + element.validCount
  }, 0)
}

const reduceTotInvalidShares = data => {
  return data.reduce((sum, element) => {
    return sum + element.invalidCount
  }, 0)
}

const reduceTotWorkerRewards = data => {
  return data.reduce((sum, element) => {
    return sum + element.reward
  }, 0)
}

const reduceAvgCoinPrice = data => {
  const incrementCount = data.length

  return incrementCount
    ? data.reduce((sum, element) => {
        return sum + element.price
      }, 0) / incrementCount
    : 0
}

export const formatHash = hash => {
  return `${hash.slice(0, 7)}…`
}

export const decimate = (number, minDecimals, maxDecimals) => {
  let unit

  if (number >= 1000000000000000000000) {
    number /= 1000000000000000000000
    unit = 'Z'
  } else if (number >= 1000000000000000000) {
    number /= 1000000000000000000
    unit = 'E'
  } else if (number >= 1000000000000000) {
    number /= 1000000000000000
    unit = 'P'
  } else if (number >= 1000000000000) {
    number /= 1000000000000
    unit = 'T'
  } else if (number >= 1000000000) {
    number /= 1000000000
    unit = 'G'
  } else if (number >= 1000000) {
    number /= 1000000
    unit = 'M'
  } else if (number >= 1000) {
    number /= 1000
    unit = 'K'
  } else {
    minDecimals = 0
    maxDecimals = 0
    unit = ''
  }

  return [
    number.toLocaleString(undefined, {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits:
        typeof maxDecimals === 'number' ? maxDecimals : minDecimals
    }),
    unit
  ]
}

export const formatHashCount = (hashCount, minDecimals, maxDecimals) => {
  return `${decimate(hashCount, minDecimals, maxDecimals).join(' ')}H`
}

export const formatHashrate = (hashrate, minDecimals, maxDecimals) => {
  return `${formatHashCount(hashrate, minDecimals, maxDecimals)}/s`
}

export const processMetrics = convertedData => {
  const reducedAvgWorkerHashrate = reduceAvgWorkerHashrate(convertedData)
  const reducedTotValidShares = reduceTotValidShares(convertedData)
  const reducedTotInvalidShares = reduceTotInvalidShares(convertedData)
  const reducedTotWorkerRewards = reduceTotWorkerRewards(convertedData)
  const reducedAvgCoinPrice = reduceAvgCoinPrice(convertedData)

  return {
    reducedAvgWorkerHashrate,
    reducedTotValidShares,
    reducedTotInvalidShares,
    reducedTotWorkerRewards,
    reducedAvgCoinPrice
  }
}

export const convertJsonToArray = data => {
  let convertedData = []

  convertedData = Object.values(data)

  Object.keys(data).forEach((key, i) => {
    convertedData[i].date = isoToLocalDate(key)
    convertedData[i].isoDate = key
  })

  return convertedData
}

export const filterDataByDate = (data, startDate, endDate) => {
  const formattedStartDate = startDate.format('YYYY/MM/DD')
  const formattedEndDate = endDate.format('YYYY/MM/DD')

  const filteredConvertedData = data.filter(
    element =>
      element.date >= formattedStartDate && element.date <= formattedEndDate
  )

  return filteredConvertedData
}

export const populateChartData = (data, chartIndex) => {
  const chartData = {
    labels: [],
    datasets: [
      {
        data: []
      }
    ]
  }

  data.forEach((value, index) => {
    const { time, date } = data[index]

    chartData.labels.push(
      time === '00:00:00' ? formatChartDate(date) : formatChartTime(time)
    )

    switch (chartIndex) {
      case 0: {
        chartData.datasets[0].data.push(
          data[index].elapsedTime
            ? data[index].hashCount / data[index].elapsedTime
            : 0
        )
        break
      }

      case 1: {
        chartData.datasets[0].data.push(
          data[index].validCount + data[index].invalidCount
        )
        break
      }

      case 2: {
        chartData.datasets[0].data.push(data[index].reward)
        break
      }

      default:
        chartData.datasets[0].data.push(data[index].price)
    }
  })

  return chartData
}
