import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { customTheme, customStyles } from '../styles'

import '../styles.scss'
import '../dark-mode.scss'

import './styles.scss'

const emailStyles = {
  ...customStyles,
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'",
    display: 'inline-block'
  })
}

const EmailDropdown = ({ options, defaultOption, width }) => {
  const history = useHistory()

  return (
    <Select
      className='react-select-container wallets'
      classNamePrefix='react-select'
      defaultValue={defaultOption}
      onChange={option => {
        history.push(`/kda/${option.value}`)
      }}
      options={options}
      isSearchable={false}
      hideSelectedOptions
      theme={customTheme}
      styles={emailStyles}
      width={width}
    />
  )
}

export default EmailDropdown
