import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

const RedirectRoute = () => {
  const authData = useContext(AuthContext)
  const { status, isAdmin } = authData
  const [walletAddress] = useState((authData.walletAddresses || {}).kda)
  const history = useHistory()

  useEffect(() => {
    switch (status) {
      case 'created':
        history.replace('/creation')
        break
      default:
        history.replace(
          walletAddress && !isAdmin ? `/kda/${walletAddress}` : '/'
        )
    }
  }, [status, isAdmin, walletAddress, history])

  return null
}

export default RedirectRoute
