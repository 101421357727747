import { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import initSentry from './utils/sentry'
import App from './App'

initSentry()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
