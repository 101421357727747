/* eslint-disable react/button-has-type */
import './styles.scss'

const Button = ({ type, onClick, label }) => (
  <button type={type} className='button-profile' onClick={onClick}>
    {label}
  </button>
)

export default Button
