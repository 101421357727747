import './styles.scss'

const Backdrop = ({ show, clicked }) =>
  show ? (
    <div
      className='backdrop'
      onClick={clicked}
      onKeyPress={clicked}
      role='button'
      tabIndex='0'
      aria-label='backdrop'
    />
  ) : null

export default Backdrop
