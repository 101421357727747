export default {
  'pre[class*="language-"]': {
    padding: '1em'
  },
  'pre[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'pre[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'code[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'code[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'pre[class*="language-"]::selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'pre[class*="language-"] ::selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'code[class*="language-"]::selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  'code[class*="language-"] ::selection': {
    textShadow: 'none',
    background: '#b3d4fc'
  },
  ':not(pre) > code[class*="language-"]': {
    background: '#f5f2f0',
    padding: '.1em',
    borderRadius: '.3em',
    whiteSpace: 'normal'
  },
  comment: {
    color: 'slategray'
  },
  prolog: {
    color: 'slategray'
  },
  doctype: {
    color: 'slategray'
  },
  cdata: {
    color: 'slategray'
  },
  punctuation: {
    color: '#999'
  },
  namespace: {
    Opacity: '.7'
  },
  property: {
    color: '#905'
  },
  tag: {
    color: '#905'
  },
  boolean: {
    color: '#905'
  },
  number: {
    color: '#905'
  },
  constant: {
    color: '#905'
  },
  symbol: {
    color: '#905'
  },
  deleted: {
    color: '#905'
  },
  selector: {
    color: '#690'
  },
  'attr-name': {
    color: '#690'
  },
  string: {
    color: '#690'
  },
  char: {
    color: '#690'
  },
  builtin: {
    color: '#690'
  },
  inserted: {
    color: '#690'
  },
  operator: {
    color: '#9a6e3a',
    background: 'hsla(0, 0%, 100%, .5)'
  },
  entity: {
    color: '#9a6e3a',
    background: 'hsla(0, 0%, 100%, .5)',
    cursor: 'help'
  },
  url: {
    color: '#9a6e3a',
    background: 'hsla(0, 0%, 100%, .5)'
  },
  '.language-css .token.string': {
    color: '#9a6e3a',
    background: 'hsla(0, 0%, 100%, .5)'
  },
  '.style .token.string': {
    color: '#9a6e3a',
    background: 'hsla(0, 0%, 100%, .5)'
  },
  atrule: {
    color: '#07a'
  },
  'attr-value': {
    color: '#07a'
  },
  keyword: {
    color: '#07a'
  },
  function: {
    color: '#DD4A68'
  },
  'class-name': {
    color: '#DD4A68'
  },
  regex: {
    color: '#e90'
  },
  important: {
    color: '#e90',
    fontWeight: 'bold'
  },
  variable: {
    color: '#e90'
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  }
}
