import './styles.scss'

const DrawerToggle = ({ clicked }) => (
  <div
    className='drawer-toggle'
    onClick={clicked}
    onKeyPress={clicked}
    role='button'
    tabIndex='0'
    aria-label='toggle sidedrawer'
  >
    <div />
    <div />
    <div />
  </div>
)

export default DrawerToggle
